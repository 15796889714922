import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CASH_IN_SUCCESS, cashIn_cashOut } from "../../Redux/Action/cashincashoutAction"
import { toast } from "react-toastify";
import { cashInOutData } from "../../Redux/Action/cashinoutdataAction";
import { useTranslation } from "react-i18next";


function InputField({ label, placeholder, value, onChange, error, numeric, maxLength, currency }) {
  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Only allow numbers and '-' prefix, no float values
    if (numeric) {
      inputValue = inputValue.replace(/[^0-9]/g, '');
      
      if (inputValue.length > 0) {
        inputValue = '-' + inputValue.slice(0, maxLength - 1);
      }
    }

    onChange(inputValue);
  };

  return (
    <>
      <div className="mt-9 font-medium max-md:max-w-full">{label}</div>
      <div className="relative">
      <span className="absolute inset-y-0 left-3 mt-2.5 flex items-center text-gray-400">{currency}</span>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        style={{ direction: "ltr" }}
        onChange={handleChange}
        className="justify-center items-start p-3 pl-14 mt-2.5 bg-white rounded-xl border border-solid border-neutral-400 border-opacity-50 text-black max-md:pr-5 focus:outline-none max-md:max-w-full w-full"
      />
      {error && <div className="text-red-500 text-xs">{error}</div>}
      </div>
    </>
  );
}

function CashOut_Modal({ onClose }) {
  const { t, i18n } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useSelector((state) => state?.login);

  const userId = useSelector(state => state.login.user.user_id);
  const sessionId = useSelector(state => state.login.user.pos_session_id);

  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");

  const session_id = useSelector(state => state.login.user.pos_session_id);
  const user_id = useSelector(state => state.login.user.user_id);
  const type = "cash out";

  const handleConfirm = async () => {
    if (!amount) {
      toast.error("Please Enter Amount");
      return;
    }

    if (parseInt(amount) > 0) {
      toast.error("Amount must be negative for Cash Out");
      return;
    }

    try {
      const response = await dispatch(cashIn_cashOut(sessionId, userId, amount, reason, type));

      console.log("ROOOOOOOO", response);
  
      // Check if the response payload has the expected error structure
      if (response.payload && response.payload.status_code === 400 && response.payload.message === "Available cash cannot be negative") {
        toast.error(t("Available cash cannot be negative"));
      } else {
        dispatch(cashInOutData(session_id, user_id, type));
        onClose();
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  

    // try {
    //   const response = await dispatch(cashIn_cashOut(sessionId, userId, amount, reason, type));
  
    //   if (response && response.type === CASH_IN_SUCCESS) {
    //     toast.success("Cash operation successful");
    //   } else {
    //     toast.error("Failed to perform cash operation");
    //   }
  
    //   dispatch(cashInOutData(session_id, user_id, type));
    //   onClose();
    // } catch (error) {
    //   console.error("Error:", error);
    //   toast.error("An error occurred. Please try again later.");
    // }
  };


  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>

      <section className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[40px] text-zinc-800 max-md:px-5 z-50" style={{ width: "600px" }}>
        <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Cash Out")}</h1>
          {/* <button className="text-zinc-800" onClick={onClose}>X</button> */}
          <button
            type="button"
            onClick={onClose}
            className="text-zinc-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </header>
        <main>
          <InputField
            label={t("Amount")}
            placeholder={t("Enter Amount")}
            value={amount}
            onChange={setAmount}
            numeric
            maxLength={15}
            currency={user?.currency}
          />
          <InputField
            label={t("Reason")}
            placeholder={t("Enter Reason")}
            value={reason}
            onChange={setReason}
          />
          {/* {inputFields.map((field, index) => (
          <InputField key={index} label={field.label} placeholder={field.placeholder} />
        ))} */}
        </main>
        <footer>
          <button className="justify-center items-center px-16 py-3.5 mt-10 text-xl text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full "
            style={{ width: "500px" }}
            onClick={handleConfirm}
          >
            {t("Confirm")}
          </button>
        </footer>
      </section>
    </div>
  );
}

export default CashOut_Modal;
