import React, { useEffect, useState } from "react";
import { ProductCart } from "./ProductCart";
import { CustomerSearchBar } from "./CustomerSearchBar";
import { useTotalAmount } from "../TotalAmountContext";
import { useItemCount } from "../ItemCountContext";
import PaymentModal from "../../Modal/Payment_Modal";
import { clear_cart_icon } from "../../../assets";
import AddCustomer_Modal from "../../Modal/AddCustomer_Modal";
import { useSelector, useDispatch } from "react-redux";
import { addProducts, clearProducts } from "../../../Redux/Action/productsAction";
import { useTranslation } from "react-i18next";



const SESSION_STORAGE_KEY = "products";


const SessionFooter = ({ isCustomerSelected, localProducts , returnProducts,totalAmount}) => {
  const { t,i18n } = useTranslation();

  // const { totalAmount, setTotalAmount } = useTotalAmount();
  const { itemCount } = useItemCount();
  const { user } = useSelector((state) => state?.login);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);

  const openPaymentModal = () => setIsPaymentModalOpen(true);
  const closePaymentModal = () => setIsPaymentModalOpen(false);

  const openAddCustomerModal = () => setIsAddCustomerModalOpen(true);
  const closeAddCustomerModal = () => {
    setIsAddCustomerModalOpen(false);
    setIsPaymentModalOpen(true);
  };

  const handlePayButtonClick = () => {
    if (!isCustomerSelected) {
      openAddCustomerModal();
    } else {
      openPaymentModal();
    }
  };

  return (
    <>
      {isPaymentModalOpen && <PaymentModal onClose={closePaymentModal} grandTotal={totalAmount} localProducts={localProducts}/>}
      {isAddCustomerModalOpen && <AddCustomer_Modal onClose={closeAddCustomerModal} />}

      {/* <footer className="fixed bottom-2 left-8 bg-white shadow-sm px-4 py-2 w-[calc(61vw-4rem)]">
        <div className="flex flex-wrap items-center text-xl font-semibold text-white gap-10">
          <div className="flex flex-col items-start">
            <div className="text-black text-sm font-normal">{t("No. of Items :")} {itemCount}</div>
            <div className="text-xl text-gray-700 justify-center items-center">
            {t("Total :")} <span className="text-2xl ml-1 text-black justify-center items-center">{totalAmount} {user?.currency}</span>
            </div>
          </div>
          <div className="absolute bottom-1 right-1">
            <button
              onClick={handlePayButtonClick}
              className={`flex flex-col justify-center ml-10 px-20 py-4 rounded-[5px] shadow-sm bg-lime-600 text-white ${localProducts.length === 0 && 'opacity-80 cursor-not-allowed'}`}
              disabled={localProducts.length === 0}
            >
              <div>{t("Pay")}</div>
            </button>
          </div>
        </div>
      </footer> */}
      <div className="rounded-md border border-solid  bottom-2 left-8 bg-white shadow-sm px-4 py-2 w-[100%]">
        <div className="flex flex-wrap items-center text-xl font-semibold justify-between text-white w-full">
          <div className="flex flex-col items-start">
            <div className="text-black text-sm font-normal">{t("Items :")} {itemCount}</div>
            <div className="text-xl text-gray-700 justify-center items-center">
              {t("Total")} : <span className="text-2xl ml-1 text-black justify-center items-center">{totalAmount} {user?.currency}</span>
            </div>
          </div>
          <div className="">
            <button
              onClick={handlePayButtonClick}
              className={`flex flex-col justify-center  px-20 py-4 rounded-[5px] shadow-sm bg-lime-600 text-white ${localProducts.length === 0 && 'opacity-80 cursor-not-allowed'}`}
              disabled={localProducts.length === 0}
            >
              <div>{t("Pay")}</div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProductBox = ({ products, onRemoveProduct, onUpdateQuantity, onClearCart, draftOrder, returnProducts }) => {
  const { t,i18n } = useTranslation();
  const { setItemCount } = useItemCount();
  const [localProducts, setLocalProducts] = useState([products]);
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount]  = useState();


  useEffect(() => {
    const productArray = Object.values(products);
    // const draftArray = draftOrder?.products;
    // const returnArray = Object.values(returnProducts || []);
    const returnArray = Object.values(returnProducts || []).map(product => ({ ...product, isReturn: true }));
    setLocalProducts([...productArray,...returnArray]);
  }, [products]);

  useEffect(() => {
    setItemCount(localProducts?.length);

    let total = 0;
    localProducts?.forEach((product) => {
      const productTotal = parseFloat(product.price || product.rate) * product.quantity;
      total += product.isReturn ? -productTotal : productTotal;
      // total += parseFloat(product.price || product?.rate) * product.quantity;
    });
  console.log("TOTAL-------->", total);

    setTotalAmount(total);

  }, [localProducts, setItemCount]);
  // console.log("TOTAL-------->", totalAmount);


  useEffect(() => {
    if (draftOrder && draftOrder.products) {
    let total = 0;
      const productArray = Object.values(draftOrder.products);
      productArray.forEach((product) => {
        total += parseFloat(product.price) * product.quantity;
      });
      setTotalAmount((prevTotal) => prevTotal + total);
    }
  }, [draftOrder]);

  // useEffect(() => {
  //   const calculateLocalProductsTotal = () => {
  //     let localTotal = 0;
  //     localProducts?.forEach((product) => {
  //       localTotal += parseFloat(product.price || product?.rate) * product.quantity;
  //     });
  //     return localTotal;
  //   };

  //   const calculateReturnedProductsTotal = () => {
  //     let returnedTotal = 0;
  //     returnProducts?.forEach((product) => {
  //       returnedTotal += parseFloat(product.price || product?.rate) * product.quantity;
  //     });
  //     return returnedTotal;
  //   };

  //   const localTotal = calculateLocalProductsTotal();
  //   const returnedTotal = calculateReturnedProductsTotal();

  //   const updatedAmount = localTotal - returnedTotal;

  //   setTotalAmount(updatedAmount);
  // }, [localProducts, returnProducts]);


  const handleClearCart = () => {
    setLocalProducts([]);
    onClearCart();
  };


  const handleCustomerSelect = (bool) => {
    // console.log("Customer selected");
    setIsCustomerSelected(bool);
  };


  return (
    <div className="flex flex-col h-[calc(120vh-4rem)]">
      <CustomerSearchBar onCustomerSelect={handleCustomerSelect} />
      <div className="flex flex-col text-sm ml-8 mt-2 tracking-wide text-black whitespace-nowrap bg-white rounded-lg border border-solid border-stone-300 w-full h-full overflow-hidden">
        <header className="flex justify-between px-4 py-2 text-base font-semibold tracking-wide bg-yellow-400 text-zinc-800">
          <div>{t("Product")}</div>
          <div className="flex items-center">
            <div className="flex mx-16">{t("Qty")}</div>
            <img
              src={clear_cart_icon}
              alt="clear_cart_icon"
              onClick={handleClearCart}
              className={`h-6 w-6 cursor-pointer transition-transform transform ${i18n.language == 'ar' ? 'scale-x-[-1]' : ''}`}
              // className="h-6 w-6 cursor-pointer"
            />
          </div>
        </header>
        <main className="flex flex-col overflow-y-auto flex-1 custom-scrollbar">
          {localProducts.map((product, index) => (
            <ProductCart
              key={index}
              product={product}
              onDelete={onRemoveProduct}
              onUpdateQuantity={onUpdateQuantity}
            />
          ))}
        </main>
        <SessionFooter isCustomerSelected={isCustomerSelected}  localProducts={localProducts}  returnedProducts={returnProducts}  totalAmount={totalAmount} />
      </div>
    </div>
  );
};
