import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSortBy, useTable, usePagination } from "react-table";
import {
  add_icon,
  barcode_icon,
  filter_icon,
  search_icon,
  substract_icon,
  view_order_icon,
} from "../../assets";
import { DatePicker, Select, Space } from "antd";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {
  fetchStoreOrders,
  setOrderSearchTerm,
} from "../../Redux/Action/orderAction";
import { format } from "date-fns";
import DraftOrdersTable from "./DraftOrdersTable";
import { useSelector, useDispatch } from "react-redux";
import { fetchPosOrderLines } from "../../Redux/Action/orderlinesAction";
import toastr from "toastr";
import { toast } from "react-toastify";
import { addProducts, clearProducts } from "../../Redux/Action/productsAction";
import { addReturnProducts } from "../../Redux/Action/returnproductAction";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../AxiosInstance";
import { fetchOrderReceipt } from "../../Redux/Action/orderReceiptAction";
import OrderInvoicePDF from "../Order/ordercomponents/OrderInvoicePDF";
import { URLS } from "../../API/API endpoints";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export function OrderSearchBar() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const dispatch = useDispatch();

  const handleSearchChange = (searchTerm) => {
    // console.log("SSSSSSSSSSSS", searchTerm);
    // dispatch(setOrderSearchTerm(searchTerm));
    dispatch(fetchStoreOrders({}, searchTerm));
  };

  return (
    <div className="relative flex ml-8 w-[33%]">
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        <img src={search_icon} alt="customers_search_icon" />
      </span>
      <input
        type="text"
        placeholder={t("Search receipt number, customer")}
        className="border p-2 pl-10 rounded w-full focus:outline-none rounded-[6px]"
        onChange={(e) => {
          const searchTerm = e.target.value;
          if (searchTerm.length >= 3) {
            handleSearchChange(searchTerm);
          }
        }}
      />
    </div>
  );
}

function InvoiceButton({ onClick }) {
  const { t, i18n } = useTranslation();

  return (
    <button
      onClick={onClick}
      className="flex flex-col justify-center ms-10 px-24 py-4 rounded-[5px] shadow-sm bg-lime-600 text-white"
    >
      <div>{t("Invoice")}</div>
    </button>
  );
}

function ReturnButton({ onClick }) {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <button
        onClick={onClick}
        className="flex flex-col justify-center ms-10 px-24 py-4 rounded-[5px] shadow-sm bg-red-600 text-white"
      >
        <div>{t("Return")}</div>
      </button>
    </div>
  );
}

function ProductList({ product, updateRefundItems }) {
  const { t, i18n } = useTranslation();
  const [quantity, setQuantity] = useState(product.quantity);
  const [isSelected, setIsSelected] = useState(false);
  const rateValue = product.rate;
  const value = product.rate * quantity;

  useEffect(() => {
    setQuantity(product?.quantity);
  }, [product?.quantity]);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
        updateRefundItems(product, newQuantity, isSelected);
        return newQuantity;
      });
    }
  };

  const increaseQuantity = () => {
    if (quantity < product.quantity) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + 1;
        updateRefundItems(product, newQuantity, isSelected);
        return newQuantity;
      });
    }
  };

  const handleProductClick = () => {
    setIsSelected((prevState) => {
      const newIsSelected = !prevState;
      updateRefundItems(product, quantity, newIsSelected);
      return newIsSelected;
    });
  };

  return (
    <div
      onClick={handleProductClick}
      className="flex items-center gap-5 p-3 text-base border-b border-solid border-slate-200 cursor-pointer"
    >
      <div className="flex items-center gap-3.5 self-stretch font-medium text-zinc-800 w-8/12">
        <img
          loading="lazy"
          src={product?.image || product?.image_url}
          alt={product?.product_name}
          className="shrink-0 aspect-square w-[35px]"
        />
        <div className="flex flex-col">
          <div className="my-auto font-semibold text-neutral-700 text-wrap">
            {product.product_name}
          </div>
          <div className="my-auto text-zinc-800 text-opacity-70">
            {/* {product.size || product?.product_uom} at {product.currency} {rateValue} */}
            {product.currency} {rateValue}
            {/* {product?.product_uom} at {product.currency} {rateValue} */}
          </div>
          {isSelected && quantity > 0 && (
            <>
              <div className="my-auto">
                {t("QTY")}: {product.quantity}
              </div>

              <div className="my-auto text-red-800">
                {t("To Refund")}: {quantity} ( {product.currency} {-value} )
              </div>
            </>
          )}
        </div>
      </div>
      {!isSelected && (
        <div className="flex items-center ms-auto gap-2 w-8 text-center">
          {t("QTY")} {product.quantity}
        </div>
      )}
      {isSelected && (
        <div className="flex items-center ms-auto gap-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              decreaseQuantity();
            }}
          >
            <img
              loading="lazy"
              src={substract_icon}
              alt="substract_icon"
              className="w-[35px]"
            />
          </button>
          <div className="w-8 text-center">{quantity}</div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              increaseQuantity();
            }}
          >
            <img
              loading="lazy"
              src={add_icon}
              alt="add_icon"
              className="w-[30px]"
            />
          </button>
        </div>
      )}
    </div>
  );
}

function AllOrderButton({ onClick, selected }) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`flex justify-center w-[25vh] py-2 font-medium hover:font-semibold text-zinc-800 bg-white border border-solid border-stone-300 whitespace-nowrap rounded-lg ${
        selected
          ? "text-blue-900 bg-white border-blue-900"
          : "bg-white border border-solid border-stone-300 text-zinc-600"
      }`}
      onClick={onClick}
    >
      <div className="mt-0.5 ms-0.5">{t("All")}</div>
    </div>
  );
}

function PaidOrderButton({ onClick }) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="flex justify-center w-[25vh] py-2 font-medium  hover:font-semibold text-zinc-800 bg-white  border border-solid border-stone-300 whitespace-nowrap rounded-lg"
      onClick={onClick}
    >
      <div className="mt-0.5 ms-0.5">{t("Paid")}</div>
    </div>
  );
}

function RefundOrderButton({ onClick }) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="flex justify-center w-[25vh] py-2 font-medium  hover:font-semibold text-zinc-800 bg-white  border border-solid border-stone-300 whitespace-nowrap rounded-lg"
      onClick={onClick}
    >
      <div className="mt-0.5 ms-0.5">{t("Refund")}</div>
    </div>
  );
}

function DraftOrderButton({ onClick, selected }) {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`flex justify-center w-[25vh] py-2 font-medium hover:font-semibold text-zinc-800 bg-white border border-solid border-stone-300 whitespace-nowrap rounded-lg ${
        selected
          ? "text-blue-900 bg-white border-blue-900"
          : "bg-white border border-solid border-stone-300 text-zinc-600"
      }`}
      onClick={onClick}
    >
      {" "}
      <div className="mt-0.5 ms-0.5">{t("Draft")}</div>
    </div>
  );
}

const FilterByEmployeeButton = ({ employees, onFilterByEmployee }) => {
  const { t, i18n } = useTranslation();
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const handleEmployeeChange = (value) => {
    setSelectedEmployee(value);
    onFilterByEmployee(value);
  };

  return (
    <Select
      defaultValue=""
      style={{ width: 150, height: 45 }}
      onChange={handleEmployeeChange}
      suffixIcon={
        <img src={filter_icon} alt="filter_icon" className="w-4 h-4" />
      }
    >
      <Select.Option value="">{t("All Employees")}</Select.Option>
      {employees.map((employee) => (
        <Select.Option key={employee} value={employee}>
          {employee}
        </Select.Option>
      ))}
    </Select>
  );
};

function Table({ columns, onRowClick, data }) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { orders } = useSelector((state) => state.orders);
  // const data = useMemo(() => orders || [], [orders]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    useSortBy,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(false);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="">
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"10px"}
          className="mb-3 mt-2"
        />
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={"40px"}
          className="mb-3"
        />
        <Skeleton
          variant="rectangular"
          width={"70%"}
          height={"80px"}
          className="mb-3"
        />
        <Skeleton
          variant="rectangular"
          width={"90%"}
          height={"20px"}
          className="mb-3"
        />
        <Skeleton
          variant="rectangular"
          width={"50%"}
          height={"40px"}
          className="mb-3"
        />
        <Skeleton
          variant="rectangular"
          width={"70%"}
          height={"80px"}
          className="mb-3"
        />
        <Skeleton
          variant="rectangular"
          width={"90%"}
          height={"20px"}
          className="mb-3"
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={"50px"}
          className="mb-3"
        />
      </div>
    );
  }

  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead className="px-10 py-4 w-full font-semibold text-lg tracking-wide text-left capitalize whitespace-nowrap bg-teal-100 text-zinc-800 max-md:px-5 max-md:max-w-full">
          <tr>
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="px-6 py-2 text-center"
                >
                  {column.render("Header")}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className=" gap-5 self-center mt-6 w-full tracking-wide capitalize max-w-[983px] text-zinc-800 max-md:flex-wrap max-md:max-w-full cursor-pointer"
                style={{ borderBottom: "1px solid #ccc" }}
                onClick={() => onRowClick(row.original)}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-center"
                  >
                    <div className="text-lg text-gray-900">
                      {cell.render("Cell")}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <footer className="flex gap-5 justify-between text-lg self-center px-5 mt-4 w-full max-w-[1134px] max-md:flex-wrap max-md:max-w-full">
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          className="px-3 py-1 rounded-lg border border-gray-100 font-semibold focus:outline-none"
        >
          {[5, 10, 15].map((pageSize) => (
            <option className="font-semibold" key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <div className="flex gap-5 text-center text-black font-semibold">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="p-2 rounded-full bg-gray-200"
            style={{ width: "40px", height: "40px" }}
          >
            &lt;
          </button>
          <div className="flex-auto my-auto">
            {t("Results")}{" "}
            <strong>
              {pageIndex * pageSize + 1}-
              {Math.min((pageIndex + 1) * pageSize, data.length)} {t("of")}{" "}
              {data.length}
            </strong>
          </div>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="p-2 rounded-full bg-gray-200"
            style={{ width: "40px", height: "40px" }}
          >
            &gt;
          </button>
        </div>
        <form
          className="flex gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            const page = e.target.pageInput.value
              ? Number(e.target.pageInput.value) - 1
              : 0;
            gotoPage(page);
          }}
        >
          <label
            htmlFor="pageInput"
            className="my-auto text-black font-semibold"
          >
            {t("Go to :")}
          </label>
          <input
            type="text"
            id="pageInput"
            placeholder="Eg: 14"
            aria-label="Go to page"
            className="px-3 pt-2.5 pb-1 font-semibold rounded-lg bg-zinc-100 text-neutral-400 w-[75px] focus:outline-none"
          />
        </form>
      </footer>
    </>
  );
}

function OrderSkeleton() {
  return (
    <Stack spacing={1} marginTop={5}>
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rectangular" height={5} />
    </Stack>
  );
}

function Order_Table() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const dispatch = useDispatch();
  const { orders = [] } = useSelector((state) => state.orders);
  const { orderLines = [] } = useSelector((state) => state.orderlinesReducer);
  const [filteredData, setFilteredData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [spinloading, setspinLoading] = useState(false);
  const [refundItems, setRefundItems] = useState({});
  const [draftOrders, setDraftOrders] = useState([]);
  const [showDraftOrdersTable, setShowDraftOrdersTable] = useState(false);
  const [showOrderTable, setShowOrderTable] = useState(true);
  const { user } = useSelector((state) => state?.login);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedButton, setSelectedButton] = useState("all");
  const [dateRange, setDateRange] = useState([null, null]);

  const { RangePicker } = DatePicker;

  const { order } = useSelector((state) => state?.orderReceiptReducer || {});

  const handleButtonClick = (orderState) => {
    setSelectedButton(orderState);
    setShowDraftOrdersTable(false);
    setShowOrderTable(true);

    const payload = { order_state: orderState };
    if (dateRange[0] && dateRange[1]) {
      payload.from = dateRange[0].format("YYYY-MM-DD");
      payload.to = dateRange[1].format("YYYY-MM-DD");
    }

    // console.log("PAAAAAAAAAAA", payload);

    dispatch(fetchStoreOrders(payload, ""));
  };

  const handleAllClick = () => handleButtonClick("all");
  const handlePaidClick = () => handleButtonClick("paid");
  const handleRefundClick = () => handleButtonClick("refund");

  const handleDraftClick = () => {
    const keys = Object.keys(localStorage);
    const draftOrderKeys = keys.filter((key) => key.startsWith("draftOrder_"));
    const draftOrders = draftOrderKeys.map((key) =>
      JSON.parse(localStorage.getItem(key))
    );
    setDraftOrders(draftOrders);
    setShowDraftOrdersTable(true);
    setShowOrderTable(false);
    setSelectedButton("draft");
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (selectedButton) {
      handleButtonClick(selectedButton);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchStoreOrders({ order_state: "all" }, ""));
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (orders) {
      setFilteredData(orders);
      const allEmployees = [...new Set(orders.map((order) => order.employee))];
      setEmployees(allEmployees);
    }
  }, [orders]);

  const filterDataByEmployee = (employee) => {
    // console.log("EMPLOYEEEE", employee);
    if (employee === "") {
      setFilteredData(orders);
    } else {
      const filtered = orders?.filter((order) => order.employee === employee);
      setFilteredData(filtered);
    }
  };
  // console.log("FilterData--->", filteredData);

  const handleRowClick = async (order) => {
    setLoading(true);
    try {
      setSelectedOrder(order);
      setDrawerOpen(true);
      await dispatch(fetchPosOrderLines(order.order_id));
    } catch (error) {
      console.error("Error fetching order lines:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDraftOrderClick = (draftOrder) => {
    setSelectedOrder(draftOrder);
    setDrawerOpen(false);
  };

  const updateRefundItems = (product, quantity, isSelected) => {
    setRefundItems((prevItems) => {
      let updatedItems = { ...prevItems };

      if (isSelected && quantity > 0) {
        const remainingQuantity =
          product.total_quantity - product.refund_quantity;

        if (remainingQuantity <= 0) {
          toast.error("No more quantity available for refund.");
          return prevItems;
        }

        if (updatedItems[product.product_id]) {
          updatedItems[product.product_id].quantity = quantity;
        } else {
          updatedItems[product.product_id] = {
            ...product,
            quantity,
            total_quantity: product.quantity,
          };
        }
      } else {
        delete updatedItems[product.product_id];
      }

      return updatedItems;
    });
  };

  const handleInvoiceClick = async () => {
    const order_id = selectedOrder.order_id;
    const store_id = user?.store_id;
    const response = await axiosInstance.post(URLS.GET_ORDER_RECEIPT, {
      store_id,
      order_id,
    });
    console.log("RESPONSE OO", response.data);
    if (response) {
      handlePrintInvoice(response.data.Order);
    }
  };

  const handlePrintInvoice = (data) => {
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(OrderInvoicePDF(data, t, user));
    doc.close();

    iframe.contentWindow.focus();

    setTimeout(() => {
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 1000);
  };

  const handleReturnClick = async () => {
    dispatch(clearProducts());

    if (Object.keys(refundItems).length === 0) {
      toast.error(t("Please select product for return."));
      return;
    }

    const refundItemsArray = Object.values(refundItems);

    setspinLoading(true);

    if (selectedOrder) {
      const order_id = selectedOrder.order_id;

      try {
        for (const item of refundItemsArray) {
          const payload = {
            order_id,
            product_variant_id: item.product_id,
            refund_qty: item.quantity,
          };

          const response = await axiosInstance.post("VerifyReturn", payload);

          if (response.data.status === 200) {
            console.log(`Product ${item.product_id} verification successful`);
          } else {
            toast.error(`Error: ${response.data.message}`);
            setspinLoading(false);

            return;
          }
        }
        dispatch(addReturnProducts(refundItemsArray));

        navigate(`/session?refundorder=${selectedOrder.order_id}`);
        setspinLoading(false);
      } catch (error) {
        // console.error("Error during return verification:", error);
        toast.error(t("This order can not be returned"));
        setspinLoading(false);
      }
    } else {
      console.error("Selected order is not available.");
    }
  };

  // const handleReturnClick = () => {
  //   dispatch(clearProducts());

  //   if (Object.keys(refundItems).length === 0) {
  //     toast.error(t("Please select product for return."));
  //     return;
  //   }

  //   const refundItemsArray = Object.values(refundItems);
  //   console.log("REFUNDDD ITMES -------->", refundItems);
  //   dispatch(addReturnProducts(refundItemsArray));

  //   if (selectedOrder) {
  //     navigate(`/session?refundorder=${selectedOrder.order_id}`);
  //   } else {
  //     console.error("Selected order is not available.");
  //   }
  // };

  const columns = useMemo(
    () => [
      {
        Header: t("Order Date"),
        accessor: "date",
        Cell: ({ value }) => (
          <div className="text-ellipsis">
            {format(new Date(value), "dd/MM/yyyy")}
          </div>
        ),
      },
      {
        Header: t("Receipt No."),
        accessor: "invoice_no",
        Cell: ({ value }) => (
          <div className="my-auto text-ellipsis">{value}</div>
        ),
      },
      {
        Header: t("Customer"),
        accessor: "customer_name",
        Cell: ({ value }) => (
          <div className="flex-auto max-md:max-w-full">{value}</div>
        ),
        sortType: "alphanumeric",
      },
      {
        Header: t("Employee"),
        accessor: "employee",
        Cell: ({ value }) => (
          <div className="flex-auto max-md:max-w-full">{value}</div>
        ),
        sortType: "alphanumeric",
      },
      {
        Header: t("Total"),
        accessor: "total",
        Cell: ({ value }) => (
          <div className="flex-auto max-md:max-w-full">
            {value} {user?.currency}
          </div>
        ),
      },
      {
        Header: " ",
        Cell: ({ row }) => (
          <button onClick={() => handleRowClick(row.original)}>
            <img
              src={view_order_icon}
              alt="view_order_icon"
              className={`w-8 h-4 transition-transform transform ${
                i18n.language == "ar" ? "scale-x-[-1]" : ""
              }`}
            />
          </button>
        ),
      },
    ],
    [user?.currency]
  );

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get("orderid");
    if (orderId) {
      const order = orders.find(
        (order) => order.order_id === parseInt(orderId)
      );
      if (order) {
        handleRowClick(order);
      }
    }
  }, [orders]);

  return (
    <>
      <div className="flex">
        {spinloading && <LoadingSpinner />}

        <div
          className={`flex-grow transition-all duration-300 ${
            drawerOpen ? "me-[33%]" : "w-full"
          }`}
        >
          <div className="bg-[#EEEEEE]">
            {/* <div className="flex flex-row gap-5 ms-12 bg-[#EEEEEE]"> */}
            <div className={`flex flex-row gap-5 ${isRtl ? 'ms-20' : 'ms-12'} bg-[#EEEEEE]`}>
              <OrderSearchBar />
              {/* <RangePicker
                placeholder={['From', 'To']}
                className="px-4 py-2 rounded-md border-none"
                onChange={handleDateChange}
              /> */}
              <FilterByEmployeeButton
                employees={employees}
                onFilterByEmployee={filterDataByEmployee}
                className="border-none"
              />
            </div>
            <div className="flex flex-row justify-start items-center ms-20 mt-4 gap-4 cursor-pointer border-none">
              <AllOrderButton
                selected={selectedButton === "all"}
                onClick={handleAllClick}
              />
              <PaidOrderButton
                onClick={handlePaidClick}
                selected={selectedButton === "paid"}
              />
              <RefundOrderButton
                onClick={handleRefundClick}
                selected={selectedButton === "refund"}
              />
              <DraftOrderButton
                onClick={handleDraftClick}
                selected={selectedButton === "draft"}
              />
            </div>
            {showDraftOrdersTable &&
              draftOrders &&
              draftOrders.length === 0 && (
                <div className="flex flex-col justify-center me-20 ms-20 mt-8 text-sm bg-white text-left rounded-[15px] overflow-hidden">
                  <p className="text-center py-4 text-lg font-semibold text-zinc-800">
                    {t("No Draft Orders")}
                  </p>
                </div>
              )}
          </div>
          {showOrderTable && (
            <div className="flex flex-col justify-center me-20 ms-20 mt-8 mb-10 pb-4 text-sm bg-white text-left rounded-[15px] overflow-hidden">
              <Table
                columns={columns}
                data={filteredData}
                onRowClick={(val) => {
                  navigate(`/orders?orderid=${val?.order_id}`);
                  handleRowClick(val);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {showDraftOrdersTable && draftOrders && draftOrders.length > 0 ? (
        <div className="flex flex-col justify-center me-20 ms-20  mt-8 text-sm  text-end ">
          <DraftOrdersTable
            draftOrders={draftOrders}
            onRowClick={handleDraftOrderClick}
          />
        </div>
      ) : (
        // <div className="flex flex-col justify-center me-20 ms-20 mt-8 text-sm bg-white text-end rounded-[15px] overflow-hidden">
        //   <DraftOrdersTable draftOrders={draftOrders} onRowClick={handleDraftOrderClick} />
        // </div>
        <>
          {drawerOpen && (
            <>
              <div
                className={`absolute flex-auto top-0 mt-20 end-0  h-[calc(122vh-4rem)] bg-white shadow-lg  p-5 transition-transform transform ${
                  drawerOpen ? "translate-x-0 w-1/3" : "translate-x-full w-0"
                }`}
              >
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-bold text-blue-900">
                    {t("Receipt No.")} {selectedOrder?.invoice_no}
                  </h2>
                  <button
                    onClick={() => setDrawerOpen(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="mt-8 flex justify-between">
                  <div className="w-50">
                    <p>
                      <strong>{t("Customer Name:")}</strong>{" "}
                      {selectedOrder?.customer_name}
                    </p>
                    <p className="mt-2">
                      <strong>{t("Mobile No.:")}</strong>{" "}
                      {selectedOrder?.phone_no}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="mb-2">
                      {/* {format(new Date(selectedOrder?.date), 'dd/MM/yyyy')} {selectedOrder?.time} */}
                    </p>
                    <span className="bg-green-200 text-green-800 font-semibold py-0.5 px-4 mt-2 rounded-full text-s ">
                      {selectedOrder?.payment_mode}
                    </span>
                  </div>
                </div>
                {loading ? (
                  <OrderSkeleton />
                ) : (
                  <div className="mt-6 overflow-y-auto h-[76%] custom-scrollbar">
                    {orderLines.map((product, index) => (
                      <ProductList
                        key={index}
                        product={{
                          product_id: product.product_id,
                          product_name: product.product_name,
                          image: product.image_url,
                          product_uom: product.uom,
                          size:
                            product.variants[0]?.Size ||
                            product.variants[0]?.Type ||
                            "",
                          quantity: product.qty,
                          rate: product.price,
                          currency: product.currency,
                          create_time: product.create_time,
                        }}
                        updateRefundItems={updateRefundItems}
                      />
                    ))}
                  </div>
                )}
                <div className="flex flex-row w-full absolute bottom-2 end-2 justify-center items-center mt-10 font-semibold text-lg">
                  <InvoiceButton onClick={handleInvoiceClick} />
                  <ReturnButton onClick={handleReturnClick} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Order_Table;
