import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { openCashRegister } from "../../Redux/Action/openingcashAction";
import { useSelector } from "react-redux";
import { getcategorydata } from "../../Redux/Action/sessionidentifierAction";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

// const InputField = ({ label, placeholder, value, onChange }) => {
//   return (
//     <>
//       <div className="mt-9 font-medium max-md:max-w-full">{label}</div>
//       <input
//         type="text"
//         placeholder={placeholder}
//         className="justify-center items-start p-3 mt-2.5 bg-white rounded-xl border border-solid border-neutral-400 border-opacity-50 focus:outline-none text-black max-md:pr-5 max-md:max-w-full w-full"
//         value={value}
//         onChange={onChange}
//       />
//     </>
//   );
// };

const InputField = ({ label, placeholder, value, onChange, currency }) => {
  return (
    <>
      <div className="mt-9 font-bold max-md:max-w-full">{label}</div>
      <div className="relative">
        <span className="absolute inset-y-0 left-3 mt-2.5 flex items-center text-gray-400">{currency}</span>
        <input
          type="text"
          style={{ direction: "ltr" }}
          placeholder={placeholder}
          className="justify-center items-start p-3 pl-14 mt-2.5 bg-white rounded-xl border border-solid border-neutral-400 border-opacity-50 focus:outline-none text-black max-md:pr-5 max-md:max-w-full w-full"
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

const OpeningCash_Modal = ({ onClose }) => {
  const { t,i18n } = useTranslation();

  const userId = useSelector(state => state.login.user.user_id);
  const sessionId = useSelector(state => state.login.user.pos_session_id);

  const [cashboxValue, setCashboxValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const handleCashboxValueChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    if (numericValue.length <= 7) {
      setCashboxValue(numericValue);
    }
    setErrorMessage('');
  };

  const handleConfirm = () => {
    if (!cashboxValue) {
      setErrorMessage("Please enter opening cash.");
      return;
    }
    dispatch(openCashRegister(cashboxValue, userId, sessionId));
    // toast.success("Successful")
    dispatch(getcategorydata(0, true, ""));
    onClose();
  };

  const { user } = useSelector((state) => state?.login);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[20px] text-zinc-800 max-md:px-5 z-40" style={{ width: "600px" }}>
        <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Enter Opening Cash")}</h1>
        </header>
        <main>
          <InputField label={t("Amount")} placeholder={t("Enter Amount")} value={cashboxValue} onChange={handleCashboxValueChange} currency={user?.currency} />
        </main>
        <footer>
          {errorMessage && (
            <div className="text-red-500 text-center mt-8">{errorMessage}</div>
          )}
          <button className="justify-center items-center px-16 py-3.5 mt-10 text-xl text-center text-white capitalize whitespace-nowrap bg-lime-500 rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full " style={{ width: "500px" }} onClick={handleConfirm} >
          {t("Confirm")}
          </button>
        </footer>
      </section>
    </div>
  );
};

export default OpeningCash_Modal;