import {store} from "./Redux/store";
import axios from "axios";

//Defaults will be combined with the instance
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// console.log("axios.defaults.baseURL", process.env);

const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    // 'api-key': 'b63bd82b-4a83-4d28-909d-30ce29614589',
    // 'lang': lang==='ar'?'ar_001':'en_US'
  },
});

//Add interceptors to add header
axiosInstance.interceptors.request.use(function (config) {
  const { login } = store.getState();
  console.log(" const { auth } = store.getState();",login)
    const auth=""
  console.log("auth", auth);
  //const token = auth?.accessToken;
   

  // const lang = auth?.lang;
  const lang = localStorage.getItem("selectedLanguage") || "ar";

  config.headers.Authorization =  `Bearer ${login?.user?.token}`
  ;
  config.headers.lang = lang === "ar" ? "ar_001" : "en_US";
  return config;
});
//Add interceptors to instance
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // showShortSnackbar(error?.message);
    return error;
  }
);

export default axiosInstance;
