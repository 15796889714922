import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  cash,
  cash_in,
  cash_out,
  open_cash,
  total_sale_icon,
} from "../../assets";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../Redux/Action/dashboarddataAction";
import { cashInOutData } from "../../Redux/Action/cashinoutdataAction";
import { useTranslation } from "react-i18next";

const dateFormat = "DD/MM/YYYY";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

function Dashboard() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { dashboardData, running, error } = useSelector(
    (state) => state.dashboard
  );
  const session_id = useSelector((state) => state.login.user.pos_session_id);
  const user_id = useSelector((state) => state.login.user.user_id);
  const { user } = useSelector((state) => state?.login);

  useEffect(() => {
    dispatch(fetchDashboardData());
    // console.log("kkokokok")
  }, []);

  useEffect(() => {
    setLoading(running);
  }, [running]);

  const handleCashInClick = () => {
    const type = "cash in";

    dispatch(cashInOutData(session_id, user_id, type));
  };

  const handleCashOutClick = () => {
    const type = "cash out";

    dispatch(cashInOutData(session_id, user_id, type));
  };

  return (
    <>
      {loading && <LoadingSpinner />}

      <div className="px-20 flex gap-4 bg-[#EEEEEE] flex-wrap w-[100%]">
        <div className="flex flex-wrap w-[100%]">
          <h1 className="text-3xl font-semibold mt-10 mb-5 text-slate-900">
            {t("Welcome")}
          </h1>
          <div className="mt-5 ms-[75%]">
            {/* <DatePicker format={dateFormat} /> */}
          </div>
        </div>

        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[380px] text-zinc-800">
          <div className="flex flex-row">
            <img
              src={total_sale_icon}
              alt=""
              className="aspect-square w-[45px]"
            />
            <h2 className="mt-2 text-xl mt-3 ms-4">{t("Total Sales")}</h2>
          </div>
          <p className="text-3xl py-6 font-semibold">
            {user?.currency} {dashboardData?.data?.total_bill_amount}
          </p>
        </div>
        {/* ))} */}

        {/* Opening Cash Card */}
        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[380px] text-zinc-800">
          <div className="flex flex-row">
            <img src={open_cash} alt="" className="aspect-square w-[45px]" />
            <h2 className="mt-2 text-xl mt-3 ms-4">{t("Opening Cash")}</h2>
          </div>
          <p className="text-3xl py-6 font-semibold">
            {user?.currency} {dashboardData?.data?.opening_cash}
          </p>
        </div>

        {/* Cash In Card */}
        <Link to="/Cashin" className="nav-link" onClick={handleCashInClick}>
          <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[380px] text-zinc-800">
            <div className="flex flex-row">
              <img src={cash_in} alt="" className="aspect-square w-[45px]" />
              <h2 className="mt-2 text-xl mt-3 ms-4">{t("Cash In")}</h2>
            </div>
            <p className="text-3xl py-6 font-semibold">
              {user?.currency} {dashboardData?.data?.cash_in}
            </p>
          </div>
        </Link>

        {/* Cash Out Card */}
        <Link to="/Cashout" className="nav-link" onClick={handleCashOutClick}>
          <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[380px] text-zinc-800">
            <div className="flex flex-row">
              <img src={cash_out} alt="" className="aspect-square w-[45px]" />
              <h2 className="mt-2 text-xl mt-3 ms-4">{t("Cash Out")}</h2>
            </div>
            <p className="text-3xl py-6 font-semibold">
              {user?.currency} {dashboardData?.data?.cash_out}
            </p>
          </div>
        </Link>

        {/* Number of Orders Card */}
        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[380px] h-[100%] text-zinc-800">
          <div className="flex flex-row">
            <img src={cash_out} alt="" className="aspect-square w-[45px]" />
            <h2 className="mt-2 text-xl mt-3 ms-4">{t("No. of Orders")}</h2>
          </div>
          <p className="text-3xl py-6 font-semibold">
            {dashboardData?.data?.order_count}
            <span className="ms-2 text-zinc-500">
              {dashboardData?.data?.order_count <= 1
                ? t("Order")
                : t("Orders")}
            </span>
          </p>
        </div>
        {/* ))} */}

        {/* Available Cash Card */}
        <div className="">
          <div className="flex flex-row items-start py-2 pr-6 pl-6 bg-[#FFF0C7] rounded-[18px] w-[1170px] h-[100%] text-zinc-800">
            <img
              src={cash}
              alt=""
              className="aspect-square w-[45px] me-4 mt-5"
            />
            <div className="flex flex-col mt-8">
              <h2 className="text-xl">{t("Available Cash")}</h2>
              <p className="text-3xl mt-4 font-semibold">
                {user?.currency} {dashboardData?.data?.available_cash}
              </p>
            </div>

            {/* Small Cards */}
            <div className="flex me-2  ms-36 gap-2 items-start">
              {/* Cash Collection */}
              <div className="flex flex-col items-start mt-2.5 py-6 px-4 bg-[#FFFAFA] rounded-[18px] w-[354px] text-zinc-800">
                <div className="flex items-center">
                  <img src={cash} alt="" className="me-2" />
                  <p className="text-3xl font-semibold">
                    {user?.currency} {dashboardData?.data?.cash_payment}
                  </p>
                </div>
                <h3 className="text-lg ms-8">{t("Cash Collection")}</h3>
              </div>
              {/* ))} */}

              {/* Bank Collection */}
              <div className="flex flex-col ms-2 items-start mt-2.5 py-6 px-4 bg-[#FFFAFA] rounded-[18px] w-[354px] text-zinc-800 ">
                <div className="flex items-center">
                  <img src={cash} alt="" className="me-2" />
                  <p className="text-3xl font-semibold">
                    {user?.currency} 0 {dashboardData.cash_payment}
                  </p>
                </div>
                <h3 className="text-lg ms-8">{t("Bank Collection")}</h3>
              </div>
            </div>
          </div>
        </div>
        {/* <Dashboard_Table></Dashboard_Table> */}
      </div>
      <div></div>
    </>
  );
}

export default Dashboard;
