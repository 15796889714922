import * as React from "react";
import { useTable, usePagination } from "react-table";
import { Link } from "react-router-dom";
import CashOutModal from '../../Modal/CashOut_Modal';
import { arrow_left, plus_icon } from '../../../assets';
import CashOut_Table from "../../Tables/CashOut_Table";
import { useTranslation } from "react-i18next";



function CashOutButton({ openModal }) {
  const { t, i18n } = useTranslation();

  return (

    <button onClick={openModal} className="flex flex-col justify-center text-xl font-semibold tracking-wide text-white capitalize">
      <div className="flex flex-col justify-center px-8 py-4 w-full bg-lime-600 rounded-lg border border-solid border-stone-300 max-md:px-5">
        <div className="flex gap-3">
          <img
            src={plus_icon}
            alt="Cash in icon"
            className="shrink-0 my-auto aspect-square w-[18px]"
          />
          <div>{t("Cash Out")}</div>
        </div>
      </div>
    </button>
  );
}

function Cashout() {
  const { t, i18n } = useTranslation();

  const [isCashOutModalOpen, setIsCashOutModalOpen] = React.useState(false);

  const openCashOutModal = () => {
    setIsCashOutModalOpen(true);
  };

  const closeCashOutModal = () => {
    setIsCashOutModalOpen(false);
  };

  return (
    <>
      {isCashOutModalOpen && <CashOutModal onClose={closeCashOutModal} />}
      <div className="flex gap-5 justify-between px-20 py-2.5 mt-5 max-md:flex-wrap max-md:px-5">
        <div className="flex flex-col justify-center text-lg font-bold text-lime-600">
          <Link to="/dashboard">
            <div className="flex flex-col justify-center px-3.5 py-3.5 w-full bg-white rounded-lg border border-lime-600 border-solid max-md:pr-5">
              <div className="flex gap-1">
                <img
                  src={arrow_left}
                  alt="Back to Dashboard icon"
                  className={`shrink-0 w-8 aspect-square ${i18n.language == 'ar' ? 'scale-x-[-1]' : ''}`}
                />
                <div className="my-auto">{t("Back to Dashboard")}</div>
              </div>
            </div>
          </Link>
        </div>
        <div><CashOutButton openModal={openCashOutModal} /></div>
      </div>
      <CashOut_Table />
      {/* <div className="flex flex-col justify-center mr-20 ml-20 mt-5 pb-4 text-lg bg-white text-left rounded-lg overflow-hidden">
        <Table columns={columns} data={transactions} />
      </div> */}
    </>
  );
}

export default Cashout