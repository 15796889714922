import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const CASH_IN_SUCCESS = 'CASH_IN_SUCCESS';
export const CASH_IN_FAILURE = 'CASH_IN_FAILURE';

export const cashIn_cashOut = (sessionId, userId, amount, reason, type) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(URLS.CASH_IN_OUT, {
        session_id: sessionId,
        user_id: userId,
        type,
        amount,
        reason
      });

      if (response.status === 200) {
        const result = dispatch(cashIn_cashOutSuccess(response.data));
        return result; // Explicitly return the result of the dispatch call
      } else {
        const errorResult = dispatch(cashIn_cashOutFailure('Failed to cash in'));
        return errorResult; // Explicitly return the result of the dispatch call
      }
    } catch (error) {
      const errorResult = dispatch(cashIn_cashOutFailure(error.response.data));
      return errorResult; // Explicitly return the result of the dispatch call
    }
  };
};

export const cashIn_cashOutSuccess = (data) => ({
  type: CASH_IN_SUCCESS,
  payload: data
});

export const cashIn_cashOutFailure = (error) => ({
  type: CASH_IN_FAILURE,
  payload: error
});
