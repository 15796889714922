import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { store } from "../store";
import { categorywisedata } from "./sessionidentifierAction";

export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const addProducts = (products) => ({
  type: ADD_PRODUCTS,
  payload: products,
});

export const clearProducts = () => ({
  type: CLEAR_PRODUCTS,
});


export const getcategorydata = (category_id,isfav,search_term,limit=40) => async(dispatch) => {
  console.log("called");

  try {
    const state = store.getState();
    const payload={
      store_id:state?.login?.user?.store_id,
      user_id:state?.login?.user?.user_id,
      category_id:category_id,
      isfav:isfav,
      search_term:search_term,
      limit:limit
    }
    const response=await axiosInstance.post(URLS.GET_PRODUCT_API,payload)
    console.log("called",response);
    if(response?.data?.status_code === 200 )dispatch(categorywisedata(response?.data?.result));
  } catch (error) {
    console.error(error)
  }
  return;
};

export const searchProduct = (searchTerm, searchBy) => (dispatch) => {
  let category_id = 0;
  let isfav = false;
  let search_term = searchTerm;
  let limit = 40;

  dispatch(getcategorydata(category_id, isfav, search_term, limit));
};