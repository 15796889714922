import React, { useState, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addProducts, clearProducts } from "../../Redux/Action/productsAction";
import { useTranslation } from "react-i18next";
import { setCustomer } from "../../Redux/Action/getcustomerAction";

function ContinueButton({ onClick, selectedOrder }) {
  const { t, i18n } = useTranslation();

  const handleContinue = () => {
    onClick();
  };

  return (
    <button
      onClick={handleContinue}
      className="flex flex-col justify-center font-semibold text-xl ms-10 px-48 py-4 mt-5 rounded-[5px] shadow-sm bg-lime-600 text-white"
    >
      <div>{t("Continue")}</div>
    </button>
  );
}

function DraftProductList({ product }) {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state?.login);

  return (
    <div className="flex items-center gap-5 p-3 text-base border-b border-solid border-slate-200 cursor-pointer">
      <div className="flex items-center gap-3.5 font-medium text-zinc-800">
        <img
          loading="lazy"
          src={product.image_url}
          alt={product.product_name}
          className="shrink-0 aspect-square object-contain w-[80px]"
        />
        <div className="flex flex-col justify-start items-start">
          <div className="my-auto text-left font-semibold text-neutral-700">
            {product.product_name}
          </div>
          <div className="my-auto  text-zinc-900 text-opacity-70">
            {t("Price")} : {user?.currency} {product.price} | {t("QTY")}:{" "}
            {product.quantity}
          </div>
          {/* <div className="my-auto text-zinc-800 text-opacity-70">
                        QTY: {product.quantity}
                    </div> */}
        </div>
      </div>
    </div>
  );
}

function DraftOrdersTable({ draftOrders, onRowClick }) {
  const { t, i18n } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state?.login);

  const navigate = useNavigate();

  const sortedDraftOrders = useMemo(() => {
    return [...draftOrders].sort((a, b) => {
      const dateA = new Date(`${a.date}T${a.time}`);
      const dateB = new Date(`${b.date}T${b.time}`);
      return dateB - dateA;
    });
  }, [draftOrders]);

  const columns = useMemo(
    () => [
      {
        Header: t("Date"),
        accessor: "date",
        Cell: ({ value }) => (
          <div className="text-ellipsis">
            {format(new Date(value), "dd/MM/yyyy")}
          </div>
        ),
      },
      {
        Header: t("Time"),
        accessor: "time",
      },
      {
        Header: t("Customer"),
        accessor: "customer_name",
      },
      {
        Header: t("No. of Items"),
        accessor: "numberOfItems",
      },
      {
        Header: t("Total"),
        accessor: "total",
        Cell: ({ value }) => <div>{`${value} ${user?.currency || "EGP"}`}</div>,
      },
    ],
    [user?.currency]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: sortedDraftOrders,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  const handleRowClick = (order) => {
    setSelectedOrder(order);
    setIsDrawerOpen(true);
    onRowClick(order);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedOrder(null);
  };

  const handleContinue = () => {
    dispatch(clearProducts());
    navigate("/session", { state: { draftOrder: selectedOrder } });
    console.log("DRAAAAA====>", selectedOrder);
    dispatch(addProducts(selectedOrder?.products));
    
    let customer_id = selectedOrder?.customer_id;
    let customer_name = selectedOrder?.customer_name;
    let customer_mobile = selectedOrder?.customer_mobile;
    let customer = { customer_id: customer_id, phone: customer_mobile, name: customer_name, }
    dispatch(setCustomer({ customer }));

    // console.log("GYAAAAAAAAAAAa",selectedOrder);
    localStorage.removeItem("draftOrder");
  };

  return (
    <>
      <div className="flex bg-transparent">
        <div
          className={`transition-all bg-white rounded-[15px] overflow-hidden duration-300 ${
            isDrawerOpen ? "flex-grow me-[33%]" : "flex-grow w-full"
          }`}
        >
          {/* <div className={`transition-all duration-300 ${isDrawerOpen ? 'flex-grow me-[33%]' : 'flex-grow w-full'}`}> */}
          <table {...getTableProps()} className="w-full cursor-pointer">
            <thead className="px-10 py-4 w-full font-semibold text-lg tracking-wide text-center capitalize whitespace-nowrap bg-teal-100 text-zinc-800 max-md:px-5 max-md:max-w-full">
              <tr>
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-6 py-2 text-center"
                    >
                      {column.render("Header")}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row.original)}
                    className="gap-5 self-center mt-6 w-full tracking-wide capitalize max-w-[983px] text-zinc-800 max-md:flex-wrap max-md:max-w-full"
                    style={{ borderBottom: "1px solid #ccc" }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className="px-6 py-4 whitespace-nowrap text-center"
                      >
                        <div className="text-lg text-gray-900">
                          {cell.render("Cell")}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <footer className="flex justify-between gap- text-lg self-center px-5 my-3 w-full">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="px-3 py-1 justify-start rounded-lg border border-gray-100 font-semibold focus:outline-none"
            >
              {[5, 10, 15].map((pageSize) => (
                <option
                  className="font-semibold"
                  key={pageSize}
                  value={pageSize}
                >
                  {pageSize}
                </option>
              ))}
            </select>
            <div className="flex gap-5 text-center text-black font-semibold">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="p-2 rounded-full bg-gray-200"
                style={{ width: "40px", height: "40px" }}
              >
                &lt;
              </button>
              <div className="flex-auto my-auto">
                {t("Results")}{" "}
                <strong>
                  {pageIndex * pageSize + 1}-
                  {Math.min(
                    (pageIndex + 1) * pageSize,
                    sortedDraftOrders.length
                  )}{" "}
                  {t("of")} {sortedDraftOrders.length}
                </strong>
              </div>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="p-2 rounded-full bg-gray-200"
                style={{ width: "40px", height: "40px" }}
              >
                &gt;
              </button>
            </div>
            <form
              className="flex gap-4"
              onSubmit={(e) => {
                e.preventDefault();
                const page = e.target.pageInput.value
                  ? Number(e.target.pageInput.value) - 1
                  : 0;
                gotoPage(page);
              }}
            >
              <label
                htmlFor="pageInput"
                className="my-auto text-black font-semibold"
              >
                {t("Go to :")}
              </label>
              <input
                type="text"
                id="pageInput"
                placeholder="Eg: 14"
                aria-label={t("Go to page")}
                className="px-3 pt-2.5 pb-1 font-semibold rounded-lg bg-zinc-100 text-neutral-400 w-[75px] focus:outline-none"
              />
            </form>
          </footer>
          {isDrawerOpen && (
            <>
              <div
                className={`fixed flex-auto top-0 mt-20 end-0 h-[calc(122vh-4rem)] bg-white shadow-lg p-5 transition-transform transform ${
                  isDrawerOpen ? "translate-x-0 w-1/3" : "translate-x-full w-0"
                }`}
              >
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-bold text-blue-900">
                    {t("Draft Order Details")}
                  </h2>
                  <button
                    onClick={closeDrawer}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="mt-8 flex justify-between text-base">
                  <div className="w-50 text-left">
                    {/* <p><strong>{t("Date")}:</strong> {selectedOrder && format(new Date(selectedOrder.date), 'dd/MM/yyyy')}</p> */}
                    <p className="mb-2">
                      <strong>{t("Customer Name")}:</strong>{" "}
                      {selectedOrder?.customer_name}
                    </p>
                    <p className="mb-2">
                      <strong>{t("Mobile No.:")}</strong>{" "}
                      {selectedOrder?.customer_name}
                    </p>
                    <p className="mb-2">
                      <strong>{t("Total Amount")}:</strong>{" "}
                      {selectedOrder?.total}
                    </p>
                  </div>
                  <div className="text-left">
                    <p className="mb-2">
                      <strong>{t("Date")}:</strong>{" "}
                      {selectedOrder &&
                        format(new Date(selectedOrder.date), "dd/MM/yyyy")}
                    </p>
                    <p className="mb-2">
                      <strong>{t("Time")}:</strong> {selectedOrder?.time}
                    </p>
                    <p className="mb-2">
                      <strong>{t("No. of Items")}:</strong>{" "}
                      {selectedOrder?.numberOfItems}
                    </p>
                  </div>
                </div>

                <h3 className="text-left text-lg font-semibold mb-2 mt-4">
                  {t("Product Details")}
                </h3>
                <div className="mt-6 overflow-y-auto xl:max-h-[calc(80vh-4rem)] lg:max-h-[calc(115vh-4rem)] custom-scrollbar">
                  {Object.values(selectedOrder.products).map(
                    (product, index) => (
                      <DraftProductList key={index} product={product} />
                    )
                  )}
                </div>
                <div className="flex flex-row w-full justify-center pe-14 absolute bottom-4 font-semibold text-lg">
                  <ContinueButton onClick={handleContinue} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DraftOrdersTable;
