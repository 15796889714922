import React from 'react';
import { Link } from 'react-router-dom';
import Order_Table from '../Tables/Orders_Table';


function Orders() {
  return (
    <>
      {/* <div className="px-20 flex gap-4 flex-wrap w-[100%]" style={{ maxWidth: "100vw", margin: "0 auto" }}> */}
      <div className='bg-[#EEEEEE] mt-5'>
        <Order_Table/>
      </div>
    </>
  )
}

export default Orders

// function Orders() {
//   return (
//     <>
//       {/* <div className="px-20 flex gap-4 flex-wrap w-[100%]" style={{ maxWidth: "100vw", margin: "0 auto" }}> */}
//       <div className='bg-[#EEEEEE]'>
//         <div className="flex gap-5 justify-between px-20 py-2.5 max-md:flex-wrap max-md:px-5">
//           <div className="flex flex-col justify-center text-2xl font-bold text-black">
//             <div className="my-auto">Orders</div>
//           </div>
//           <Link to="/session" className="nav-link">
//             {/* <div><NewOrderButton/></div> */}
//           </Link>
//         </div>
//         <Order_Table></Order_Table>
//       </div>
//     </>
//   )
// }

// export default Orders




// import React, { useEffect, useMemo, useState } from "react";
// import { Link } from "react-router-dom";
// import { useTable, usePagination } from 'react-table';
// import { add_icon, filter_icon, search_icon, substract_icon, view_order_icon } from '../../assets';
// import { DatePicker, Select } from 'antd';
// import Skeleton from '@mui/material/Skeleton';
// import Stack from '@mui/material/Stack';
// import { PosOrderLines } from "../../Api"
// import { fetchStoreOrders } from "../../Redux/Action/orderAction";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { format } from 'date-fns'

// export function OrderSearchBar() {
//   return (
//     <div className="relative flex ml-8 w-[33%]">
//       <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//         <img src={search_icon} alt="customers_search_icon" />
//       </span>
//       <input
//         type="text"
//         placeholder="Search invoice number, customer"
//         className="border p-2 pl-10 rounded w-full focus:outline-none rounded-[6px]"
//       />
//     </div>
//   );
// }

// function InvoiceButton() {
//   return (
//     <button className="flex flex-col justify-center ml-10 px-20 py-4 rounded-[5px] shadow-sm bg-lime-600 text-white">
//       <div>Invoice</div>
//     </button>
//   );
// }


// function ReturnButton({ onClick }) {
//   return (
//     <Link to="/session">
//       <button onClick={onClick} className="flex flex-col justify-center ml-10 px-20 py-4 rounded-[5px] shadow-sm bg-red-600 text-white">
//         <div>Return</div>
//       </button>
//     </Link>
//   );
// }



// function ProductList({ product, updateRefundItems }) {
//   const [quantity, setQuantity] = useState(product.quantity);
//   const [isSelected, setIsSelected] = useState(false);
//   const rateValue = product.rate;
//   const value = product.rate * quantity;

//   useEffect(() => {
//     setQuantity(product?.quantity);
//   }, [product?.quantity]);

//   const decreaseQuantity = () => {
//     if (quantity > 0) {
//       setQuantity(prevQuantity => {
//         const newQuantity = prevQuantity - 1;
//         updateRefundItems(product, newQuantity, isSelected);
//         return newQuantity;
//       });
//     }
//   };

//   const increaseQuantity = () => {
//     if (quantity < product.quantity) {
//       setQuantity(prevQuantity => {
//         const newQuantity = prevQuantity + 1;
//         updateRefundItems(product, newQuantity, isSelected);
//         return newQuantity;
//       });
//     }
//   };

//   const handleProductClick = () => {
//     setIsSelected(prevState => {
//       const newIsSelected = !prevState;
//       updateRefundItems(product, quantity, newIsSelected);
//       return newIsSelected;
//     });
//   };

//   return (
//     <div onClick={handleProductClick} className="flex items-center gap-5 p-3 text-base border border-solid border-slate-200 cursor-pointer">
//       <div className="flex items-center gap-3.5 self-stretch font-medium text-zinc-800 w-8/12">
//         <img
//           loading="lazy"
//           src={product.image}
//           alt={product.name}
//           className="shrink-0 aspect-square w-[35px]"
//         />
//         <div className="flex flex-col">
//           <div className="my-auto font-semibold text-neutral-700 text-wrap">
//             {product.name}
//           </div>
//           <div className="my-auto text-zinc-800 text-opacity-70">
//             {product.size} at {product.currency} {rateValue}
//           </div>
//           {isSelected && quantity > 0 && (
//             <div className="my-auto text-red-800">
//               To Refund: {quantity} ( {product.currency} {-value} )
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="flex items-center ml-auto gap-2">
//         <button onClick={(e) => { e.stopPropagation(); decreaseQuantity(); }}>
//           <img
//             loading="lazy"
//             src={substract_icon}
//             alt="substract_icon"
//             className="w-[35px]"
//           />
//         </button>
//         <div className="w-8 text-center">{quantity}</div>
//         <button onClick={(e) => { e.stopPropagation(); increaseQuantity(); }}>
//           <img
//             loading="lazy"
//             src={add_icon}
//             alt="add_icon"
//             className="w-[30px]"
//           />
//         </button>
//       </div>
//     </div>
//   );
// }

// function AllOrderButton() {
//   return (
//     <div className="flex justify-center w-[25vh] py-2 font-medium  hover:font-semibold text-zinc-800 bg-white  border border-solid border-stone-300 whitespace-nowrap rounded-lg">
//       <div className="mt-0.5 ml-0.5">All</div>
//     </div>
//   );
// }

// function PaidOrderButton() {
//   return (
//     <div className="flex justify-center w-[25vh] py-2 font-medium  hover:font-semibold text-zinc-800 bg-white  border border-solid border-stone-300 whitespace-nowrap rounded-lg">
//       <div className="mt-0.5 ml-0.5">Paid</div>
//     </div>
//   );
// }

// function RefundOrderButton() {
//   return (
//     <div className="flex justify-center w-[25vh] py-2 font-medium  hover:font-semibold text-zinc-800 bg-white  border border-solid border-stone-300 whitespace-nowrap rounded-lg">
//       <div className="mt-0.5 ml-0.5">Refund</div>
//     </div>
//   );
// }

// function DraftOrderButton() {
//   return (
//     <div className="flex justify-center w-[25vh] py-2 font-medium  hover:font-semibold text-zinc-800 bg-white  border border-solid border-stone-300 whitespace-nowrap rounded-lg">
//       <div className="mt-0.5 ml-0.5">Draft</div>
//     </div>
//   );
// }

// const FilterByEmployeeButton = ({ employees, onFilterByEmployee }) => {
//   const [selectedEmployee, setSelectedEmployee] = useState('');

//   const handleEmployeeChange = (value) => {
//     setSelectedEmployee(value);
//     onFilterByEmployee(value);
//   };

//   return (
//     <Select
//       defaultValue=""
//       style={{ width: 150, height: 45 }}
//       onChange={handleEmployeeChange}
//       suffixIcon={<img src={filter_icon} alt="filter_icon" className="w-4 h-4" />}
//     >
//       <Select.Option value="">All Employees</Select.Option>
//       {employees.map((employee) => (
//         <Select.Option key={employee} value={employee}>
//           {employee}
//         </Select.Option>
//       ))}
//     </Select>
//   );
// };

// function Table({ columns, onRowClick }) {
//   const [isLoading, setIsLoading] = useState(true);
//   const { orders } = useSelector((state) => state.orders)
//   const data = useMemo(() => orders || [], [orders]);


//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     prepareRow,
//     page,
//     canPreviousPage,
//     canNextPage,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state: { pageIndex, pageSize },
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageIndex: 0, pageSize: 5 },
//     },
//     usePagination
//   );

//   useEffect(() => {
//     if (data.length > 0) {
//       setIsLoading(false);
//     }
//   }, [data]);

//   if (isLoading) {
//     return (
//       <div className="">
//         <Skeleton variant="rectangular" width={'100%'} height={'10px'} className="mb-3 mt-2" />
//       </div>
//     );
//   }

//   return (
//     <>
//       <table {...getTableProps()} className="w-full">
//         <thead className="px-10 py-4 w-full font-semibold text-lg tracking-wide text-left capitalize whitespace-nowrap bg-teal-100 text-zinc-800 max-md:px-5 max-md:max-w-full">
//           <tr>
//             {headerGroups.map(headerGroup => (
//               headerGroup.headers.map(column => (
//                 <th
//                   {...column.getHeaderProps()}
//                   className="px-6 py-2 text-center"
//                 >
//                   {column.render("Header")}
//                 </th>
//               ))
//             ))}
//           </tr>
//         </thead>
//         <tbody {...getTableBodyProps()}>
//           {page.map((row, i) => {
//             prepareRow(row);
//             return (
//               <tr
//                 {...row.getRowProps()}
//                 className=" gap-5 self-center mt-6 w-full tracking-wide capitalize max-w-[983px] text-zinc-800 max-md:flex-wrap max-md:max-w-full cursor-pointer"
//                 style={{ borderBottom: "1px solid #ccc" }}
//                 onClick={() => onRowClick(row.original)}
//               >
//                 {row.cells.map(cell => (
//                   <td
//                     {...cell.getCellProps()}
//                     className="px-6 py-4 whitespace-nowrap text-center"
//                   >
//                     <div className="text-lg text-gray-900">
//                       {cell.render("Cell")}
//                     </div>
//                   </td>
//                 ))}
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>

//       <footer className="flex gap-5 justify-between text-lg self-center px-5 mt-4 w-full max-w-[1134px] max-md:flex-wrap max-md:max-w-full">
//         <select
//           value={pageSize}
//           onChange={e => setPageSize(Number(e.target.value))}
//           className="px-3 py-1 rounded-lg border border-gray-100 font-semibold focus:outline-none"
//         >
//           {[5, 10, 15].map(pageSize => (
//             <option className="font-semibold" key={pageSize} value={pageSize}>
//               {pageSize}
//             </option>
//           ))}
//         </select>
//         <div className="flex gap-5 text-center text-black font-semibold">
//           <button onClick={() => previousPage()} disabled={!canPreviousPage} className="p-2 rounded-full bg-gray-200" style={{ width: "40px", height: "40px" }}>
//             &lt;
//           </button>
//           <div className="flex-auto my-auto">
//             Results{" "}
//             <strong>
//               {pageIndex * pageSize + 1}-{Math.min((pageIndex + 1) * pageSize, data.length)} of {data.length}
//             </strong>
//           </div>
//           <button onClick={() => nextPage()} disabled={!canNextPage} className="p-2 rounded-full bg-gray-200" style={{ width: "40px", height: "40px" }}>
//             &gt;
//           </button>
//         </div>
//         <form
//           className="flex gap-4"
//           onSubmit={(e) => {
//             e.preventDefault();
//             const page = e.target.pageInput.value
//               ? Number(e.target.pageInput.value) - 1
//               : 0;
//             gotoPage(page);
//           }}
//         >
//           <label htmlFor="pageInput" className="my-auto text-black font-semibold">
//             Go to:
//           </label>
//           <input
//             type="text"
//             id="pageInput"
//             placeholder="Eg: 14"
//             aria-label="Go to page"
//             className="px-3 pt-2.5 pb-1 font-semibold rounded-lg bg-zinc-100 text-neutral-400 w-[75px] focus:outline-none"
//           />
//         </form>
//       </footer>
//     </>
//   );
// }


// function OrderSkeleton() {
//   return (
//     <Stack spacing={1}>
//       <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
//     </Stack>
//   );
// }

// function Order_Table() {
//   const dispatch = useDispatch()
//   const { orders } = useSelector((state) => state.orders)
//   const [filteredData, setFilteredData] = useState([]);
//   const [employees, setEmployees] = useState([]);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [orderLines, setOrderLines] = useState([]);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [refundItems, setRefundItems] = useState(new Set()); // Use a Set for refund items



//   const { RangePicker } = DatePicker;

//   useEffect(() => {
//     // setLoading(false);
//     const fetchData = async () => {
//       try {

//         dispatch(fetchStoreOrders());
//         // const orders = response.Orders.map(order => ({
//         //   ...order,
//         //   date: new Date(order.date).toLocaleDateString()
//         // }));
//         const orders = [];
//         // setData(orders);
//         setFilteredData(orders);
//         const allEmployees = [...new Set(orders.map(order => order.employee))];
//         setEmployees(allEmployees);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching orders:', error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const filterDataByEmployee = []

//   const handleRowClick = async (order) => {
//     setSelectedOrder(order);
//     setLoading(true);
//     try {
//       setDrawerOpen(true);
//       const response = await PosOrderLines(order.order_id);
//       setOrderLines(response.result.records);
//     } catch (error) {
//       console.error('Error fetching order lines:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const updateRefundItems = (product, quantity, isSelected) => {
//     setRefundItems(prevItems => {
//       const updatedItems = new Map(prevItems);

//       if (isSelected && quantity > 0) {
//         updatedItems.set(product.id, { ...product, quantity, product_id: product.id });
//       } else {
//         updatedItems.delete(product.id);
//       }

//       return updatedItems;
//     });
//   };

//   console.log("UPDATED Items", refundItems);

//   const handleReturnClick = () => {
//     const refundItemsArray = Array.from(refundItems.values());
//     localStorage.setItem('refundOrder', JSON.stringify(refundItemsArray));
//   };


//   const columns = useMemo(
//     () => [
//       {
//         Header: "Date",
//         accessor: "date",
//         Cell: ({ value }) => <div className="text-ellipsis">{format(new Date(value), 'dd/MM/yyyy')}</div>,
//       },
//       {
//         Header: "Invoice No.",
//         accessor: "invoice_no",
//         Cell: ({ value }) => <div className="my-auto text-ellipsis">{value}</div>,
//       },
//       {
//         Header: "Customer",
//         accessor: "customer_name",
//         Cell: ({ value }) => (
//           <div className="flex-auto max-md:max-w-full">{value}</div>
//         ),
//         sortType: "alphanumeric",
//       },
//       {
//         Header: "Employee",
//         accessor: "employee",
//         Cell: ({ value }) => (
//           <div className="flex-auto max-md:max-w-full">{value}</div>
//         ),
//         sortType: "alphanumeric",
//       },
//       {
//         Header: "Total",
//         accessor: "total",
//         Cell: ({ value }) => (
//           <div className="flex-auto max-md:max-w-full">{value}</div>
//         ),
//       },
//       {
//         Header: " ",
//         Cell: ({ row }) => (
//           <button onClick={() => handleRowClick(row.original)}>
//             <img src={view_order_icon} alt="view_order_icon" className="w-8 h-4" />
//           </button>
//         ),
//       }
//     ],
//     []
//   );
//   console.log("order-->", selectedOrder)
//   return (
//     <>
//       <div className="flex">
//         <div className={`flex-grow transition-all duration-300 ${drawerOpen ? 'mr-[33%]' : 'w-full'}`}>
//           <div className="bg-[#EEEEEE]">
//             <div className='flex flex-row gap-5 ml-12 bg-[#EEEEEE]'>
//               <OrderSearchBar />
//               <RangePicker
//                 placeholderText="Order Date"
//                 className="px-4 py-2 rounded-md"
//               />
//               <FilterByEmployeeButton employees={employees} onFilterByEmployee={filterDataByEmployee} />
//             </div>
//             <div className="flex flex-row justify-start items-center ml-20 mt-4 gap-4 cursor-pointer">
//               <AllOrderButton />
//               <PaidOrderButton />
//               <RefundOrderButton />
//               <DraftOrderButton />
//             </div>
//             <div className="flex flex-col justify-center mr-20 ml-20 mt-5 mb-10 pb-4 text-sm bg-white text-left rounded-[15px] overflow-hidden">
//               <Table columns={columns} data={filteredData} onRowClick={handleRowClick} />
//             </div>
//           </div>
//         </div>


//         <div className={`fixed flex-auto top-0 mt-20 right-0 h-[150vh] bg-white shadow-lg z-50 p-5 transition-transform transform ${drawerOpen ? 'translate-x-0 w-1/3' : 'translate-x-full w-0'}`}>
//           <div className="flex justify-between items-center">
//             <h2 className="text-xl font-bold text-blue-900">Invoice No. {selectedOrder?.invoice_no}</h2>
//             <button onClick={() => setDrawerOpen(false)} className="text-gray-500 hover:text-gray-700">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="w-6 h-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//           </div>
//           {drawerOpen && (
//             <>
//               <div className="mt-8 flex justify-between">
//                 <div className="w-50">
//                   <p><strong>Customer Name:</strong> {selectedOrder.customer_name}</p>
//                   <p className="mt-2"><strong>Phone No.:</strong> {selectedOrder.phone_no}</p>
//                 </div>
//                 <div className="text-right">

//                   <p className="mb-2">
//                     {format(new Date(selectedOrder.date), 'dd/MM/yyyy')} {selectedOrder.time}
//                   </p>
//                   <span className="bg-green-200 text-green-800 font-semibold py-0.5 px-4 mt-2 rounded-full text-s ">{selectedOrder.payment_mode}</span>
//                 </div>
//               </div>
//               {loading ? (
//                 <OrderSkeleton />
//               ) : (
//                 <div className="mt-6 overflow-y-auto max-h-[80vh] custom-scrollbar">
//                   {orderLines.map((product, index) => (
//                     <ProductList key={index} product={{
//                       id: product.product_id,
//                       name: product.product_name,
//                       image: product.image_url,
//                       size: product.variants[0]?.Size || product.variants[0]?.Type || '',
//                       quantity: product.qty,
//                       rate: product.price,
//                       currency: product.currency,
//                       create_time: product.create_time,
//                     }} updateRefundItems={updateRefundItems} />
//                   ))}
//                 </div>
//               )}
//               <div className="flex flex-row absolute bottom-2 right-1 justify-center items-center mt-10 mr-10 font-semibold text-lg">
//                 <InvoiceButton />
//                 <ReturnButton onClick={handleReturnClick} />
//               </div>
//             </>
//           )}

//         </div>
//       </div>
//     </>
//   );
// }

// export default Order_Table;