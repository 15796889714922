import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const createOrder = (payload) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_ORDER_REQUEST });
        try {
            console.log("Creating order with payload:", payload); // Add this line
            const response = await axiosInstance.post(URLS.CREATE_POS_ORDER, payload);
            console.log("Order creation response:", response); // Add this line
            dispatch({ type: CREATE_ORDER_SUCCESS, payload: response.data.result });
            return response.data
        } catch (error) {
            console.error('Error creating order:', error);
            dispatch({ type: CREATE_ORDER_FAILURE, payload: error.message });
        }
    };
};