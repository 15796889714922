import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cashIn_cashOut } from "../../Redux/Action/cashincashoutAction"
import { toast } from "react-toastify";
import { cashInOutData } from "../../Redux/Action/cashinoutdataAction";
import { useTranslation } from "react-i18next";


function InputField({ label, placeholder, value, onChange, currency , numeric, maxLength }) {

  const handleChange = (e) => {
        const inputValue = e.target.value;
    
        if (numeric && !/^\d*$/.test(inputValue)) {
          return; 
        }
    
        if (maxLength && inputValue.length > maxLength) {
          return;
        }
    
        onChange(inputValue); 
      };
  return (
    <>

    <div className="mt-9 font-bold max-md:max-w-full">{label}</div>
    <div className="relative">
      <span className="absolute inset-y-0 left-3 mt-2.5 flex items-center text-gray-400">{currency}</span>
      <input
        type="text"
        style={{ direction: "ltr" }}
        placeholder={placeholder}
        className="justify-center items-start p-3 pl-14 mt-2.5 bg-white rounded-xl border border-solid border-neutral-400 border-opacity-50 focus:outline-none text-black max-md:pr-5 max-md:max-w-full w-full"
        value={value}
        onChange={handleChange}
      />
    </div>
      {/* <div className="mt-9 font-medium max-md:max-w-full">{label}</div>
      <span className="absolute inset-y-0 left-3 mt-2.5 flex items-center text-black">EGP</span>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        style={{ direction: 'ltr' }}
        onChange={handleChange}
        // onChange={(e) => onChange(e.target.value)}
        className="justify-center items-start p-3 mt-2.5 bg-white rounded-xl border border-solid border-neutral-400 border-opacity-50 text-black max-md:pr-5 focus:outline-none max-md:max-w-full w-full"
      /> */}
       {/* <span className="absolute inset-y-10 right-10 flex items-center pr-3 text-gray-400">{currency}</span> */}
    </>
  );
}

function CashIn_Modal({ onClose }) {
  const { t, i18n } = useTranslation();


  const userId = useSelector(state => state.login.user.user_id);
  const sessionId = useSelector(state => state.login.user.pos_session_id);

  const dispatch = useDispatch();


  const [isModalOpen, setIsModalOpen] = useState(false);

  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");

  const session_id = useSelector(state => state.login.user.pos_session_id);
  const user_id = useSelector(state => state.login.user.user_id);
  const type = "cash in";

  const handleConfirm = async () => {
    if (!amount) {
      toast.error(t("Please Enter Amount"));
      return;
    }

    if (!/^\d{1,15}$/.test(amount)) {
      toast.error(t("Amount must be numeric and cannot exceed 15 digits"));
      return;
    }

    dispatch(cashIn_cashOut(sessionId, userId, amount, reason, type));
    dispatch(cashInOutData(session_id, user_id, type));
    // toast.success("Cash In Successfull")
    onClose();

  };

  const { user } = useSelector((state) => state?.login);




  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section className="flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[40px] text-zinc-800 max-md:px-5 z-50" style={{ width: "600px" }}>
        <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Cash In")}</h1>
          {/* <button className="text-zinc-800" onClick={onClose}>X</button>  */}
          <button
            type="button"
            onClick={onClose}
            className="text-zinc-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </header>
        <main>
          <InputField
            label={t("Amount")}
            placeholder={t("Enter Amount")}
            value={amount}
            onChange={setAmount}
            currency={user?.currency}
            numeric
            maxLength={15}
          />
          <InputField
            label={t("Reason")}
            placeholder={t("Enter Reason")}
            value={reason}
            onChange={setReason}
          />
          {/* {inputFields.map((field, index) => (
            <InputField key={index} label={field.label} placeholder={field.placeholder} />
          ))} */}
        </main>
        <footer>
          <button className="justify-center items-center px-16 py-3.5 mt-10 text-xl text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full "
            style={{ width: "500px" }}
            onClick={handleConfirm}
          >
            {t("Confirm")}
          </button>
        </footer>
      </section>
    </div>
  );
}

export default CashIn_Modal;
