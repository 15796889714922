import React, { useState, useRef, useEffect } from "react";
import { return_icon, plus_icon, star_icon } from "../../assets";
import { Link, useLocation } from "react-router-dom";
import { ProductBox } from "./sessioncomponents/ProductBox";
import ProductSearchBar from "./sessioncomponents/ProductSearchBar";
import ProductGrid from "./sessioncomponents/ProductGrid";
import { URLS } from "../../API/API endpoints";
import { useSelector } from "react-redux";
import axiosInstance from "../../AxiosInstance";
import OpeningCash_Modal from "../Modal/OpeningCash_Modal";
import {
  getcategorydata,
  sessionstatesave,
} from "../../Redux/Action/sessionidentifierAction";
import { useDispatch } from "react-redux";
import BarcodeReader from 'react-barcode-reader'
import debounce from "lodash/debounce";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { addProducts, clearProducts, searchProduct } from "../../Redux/Action/productsAction";
import { addReturnProducts, clearReturnProducts } from "../../Redux/Action/returnproductAction";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { clearCustomer } from "../../Redux/Action/getcustomerAction";

const ReturnButton = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex justify-center me-5 w-[25vh] py-2 font-semibold bg-gradient-to-b from-red-400 via-red-500 to-red-500 text-white whitespace-nowrap rounded-lg">
      <img
        src={return_icon}
        alt="return_icon"
        className="shrink-0 self-start w-6 aspect-[0.96]"
      />
      <div className="mt-0.5 ml-0.5">{t("Return")}</div>
    </div>
  );
}

const NewOrderButton = ({ onClick }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      onClick={onClick}
      className="flex justify-center w-[28vh] py-2 font-semibold bg-gradient-to-b from-blue-800 via-blue-900 to-blue-900 text-white whitespace-nowrap rounded-lg cursor-pointer"
    >
      <img src={plus_icon} alt="plus_icon" className=" mt-1 self-start w-5" />
      <div className="mt-0.5 ml-1 mr-2">{t("New Order")}</div>
    </div>
  );
}

const CategoryButton = ({ children, active, onClick }) => (
  <button
    className={`flex-none justify-center px-5 py-1 mt-2 border border-solid border-stone-300 rounded-lg font-bold shadow-sm mr-2 ${active ? "text-white bg-[#76CFEE]" : "text-gray-600 bg-white"
      }`}
    onClick={onClick}
  >
    {children}
  </button>
);

function CategorySkeleton() {
  return (
    <Stack direction="row" spacing={1} sx={{ marginTop: '0.5rem' }}>
      <Skeleton variant="rounded" width={60} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={90} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
      <Skeleton variant="rounded" width={100} height={35} />
    </Stack>
  );
}

function ProductGridSkeleton() {
  const rows = 3;
  const columns = 6;
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';


  return (
    <div>
      {[...Array(rows)].map((_, rowIndex) => (
        <Stack key={rowIndex} direction="row" spacing={1} sx={{ marginTop: rowIndex === 0 ? '1rem' : '1rem' }}>
          {[...Array(columns)].map((_, colIndex) => (
            <Stack key={colIndex} spacing={1}>
              <Skeleton variant="rounded" width={178} height={162} />
              <Skeleton variant="text" width={128} sx={{ fontSize: '0.5rem', marginTop: '2rem' }} />
              <Skeleton variant="text" width={100} sx={{ fontSize: '0.5rem', marginTop: '2rem' }} />
            </Stack>
          ))}
        </Stack>
      ))}
    </div>
  );
}


const DRAFT_ORDER_KEY = "draftOrder";

const ProductCo = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { draftOrder } = location.state || {};
  const { store_id, user_id } = useSelector((state) => state?.login?.user);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState(draftOrder?.products || []);
  const [isScrollAtStart, setIsScrollAtStart] = useState(true);
  const [isScrollAtEnd, setIsScrollAtEnd] = useState(false);
  const [categories, setcategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(false);
  const { OpenCashModal } = useSelector((state) => state?.sessionIdentify);
  const carouselRef = useRef(null);
  const [search, setsearch] = useState("");
  const [categoryid, setcategoryid] = useState(0);
  const [isfav, setisfav] = useState(false);
  const [isCartCleared, setIsCartCleared] = useState(false);
  const localProducts = useSelector((state) => state.products.products);
  const returnProducts = useSelector((state) => state.returnProductReducer.products)
  const [barcode, setBarcode] = useState('');
  const isRtl = i18n.dir() === 'rtl';
  const { selectedCustomer } = useSelector((state) => state?.getcustomer);


  const customer_name = selectedCustomer?.customer?.name;
  const customer_mobile = selectedCustomer?.customer?.phone;
  const customer_id = selectedCustomer?.customer?.customer_id;




  // const [categories, setCategories] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  // console.log("RETURN PRODUCTS +++++>", returnProducts);



  const dispatch = useDispatch();

  useEffect(() => {
    getcategories();
    getProductData(0, true, '', 'TEXT')
  }, []);

  useEffect(() => {
    if (!isCartCleared && location.state && location.state.draftOrder) {
      setSelectedProducts(location.state.draftOrder.products);
    }
  }, [location.state, isCartCleared]);

  useEffect(() => {
    if (!isCartCleared) {
      // console.log("Draft Order:", draftOrder);
    }
  }, [draftOrder, isCartCleared]);

  const getcategories = async () => {
    setCategoriesLoading(true);
    const response = await axiosInstance.post(URLS.GET_CATEGORIES, {
      store_id: store_id,
    });
    if (response?.data?.status === 200)
      setcategories(response?.data?.categories);
    setCategoriesLoading(false);
    // console.log("response", response);
  };

  const getProductData = async (categoryid, isfav, val, searchBy) => {
    const payload = {
      store_id: store_id,
      user_id: user_id,
      category_id: categoryid,
      isfav: isfav,
      search_term: val,
      limit: 50
    }
    const response = await axiosInstance.post(URLS.GET_PRODUCT_API, payload)
    if (response?.data?.status_code === 200) {
      if (searchBy === 'BARCODE') {
        handleSelectProduct(response?.data?.result[0])
      } else {
        setProducts(response?.data?.result);
      }
    } else {
      if (searchBy === 'BARCODE') {
        toast.error('Product not found')
      } else {
        setProducts([]);
      }
    }
  };

  const searchfunction = debounce((val) => {
    if (val.length == 0 || val.length >= 3) {
      getProductData(0, false, val, 'TEXT');
    }
  }, 500);



  const handleNewOrderClick = () => {
    dispatch(clearProducts());

    let lastOrderId = localStorage.getItem("lastOrderId") || 0;
    const draftOrderId = ++lastOrderId;

    let totalQuantity = 0;
    let totalPrice = 0;

    Object.values(localProducts).forEach(product => {
      totalQuantity += product.quantity;
      totalPrice += product.quantity * product.price;
    });

    const draftOrder = {
      id: draftOrderId,
      products: localProducts,
      date: new Date().toISOString().slice(0, 10),
      time: new Date().toLocaleTimeString(),
      numberOfItems: Object.keys(localProducts).length,
      total: totalPrice.toFixed(2),
      timestamp: Date.now(),
      customer_id,
      customer_name,
      customer_mobile, 
    };

    localStorage.setItem(`draftOrder_${draftOrderId}`, JSON.stringify(draftOrder));
    localStorage.setItem("lastOrderId", lastOrderId);
    localStorage.removeItem(DRAFT_ORDER_KEY);
    setSelectedProducts({});
    dispatch(clearCustomer())
  };

  useEffect(() => {
    const draftOrderKeys = Object.keys(localStorage).filter(key => key.startsWith("draftOrder_"));
    const currentTimestamp = Date.now();

    draftOrderKeys.forEach(key => {
      const draftOrder = JSON.parse(localStorage.getItem(key));
      const timeDifference = currentTimestamp - draftOrder.timestamp;
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000;

      if (timeDifference >= twentyFourHoursInMs) {
        localStorage.removeItem(key);
      }
    });
  }, []);

  const handleSelectProduct = (product) => {
    // setSelectedProducts((prev) => {
    const updatedProducts = { ...localProducts };
    if (updatedProducts[product.product_id]) {
      updatedProducts[product.product_id].quantity += 1;
    } else {
      updatedProducts[product.product_id] = { ...product, quantity: 1 };
    }
    dispatch(addProducts(updatedProducts));
    // });
  };

  const handleRemoveProduct = (product) => {
    // console.log("product", product);

    const updatedProducts = { ...localProducts };

    if (updatedProducts[product.product_id]) {
      delete updatedProducts[product.product_id];
    }

    Object.keys(updatedProducts).forEach(key => {
      if (updatedProducts[key].product_id === product.product_id) {
        delete updatedProducts[key];
      }
    });

    dispatch(addProducts(updatedProducts));
  };
  // console.log("PRODUCT ARRAY --------->", localProducts);

  const handleUpdateQuantity = (productId, quantity) => {
    if (quantity < 1) {
      return;
    }
    // setSelectedProducts((prev) => {
    const updatedProducts = { ...localProducts };
    if (updatedProducts[productId]) {
      updatedProducts[productId].quantity = quantity;
    }
    Object.keys(updatedProducts).forEach(key => {
      if (updatedProducts[key].product_id === productId) {
        updatedProducts[key].quantity = quantity;

      }
    });

    dispatch(addProducts(updatedProducts));
    // });
  };

  const handleClearCart = () => {
    localStorage.removeItem(DRAFT_ORDER_KEY);


    dispatch(clearProducts());
    dispatch(clearReturnProducts())
    location.state = {};
    setIsCartCleared(true);
  };

  // const handleCategoryClick = async (index, id) => {
  //   setActiveIndex(index);
  //   setProductsLoading(true); 

  //   if (id === "fav") {
  //     setcategoryid(0);
  //     setisfav(true);
  //     dispatch(getcategorydata(0, true, ""));
  //   } else {
  //     setisfav(false);
  //     console.log("called", index, id);
  //     setcategoryid(id);
  //     dispatch(getcategorydata(id, false, search));
  //     // setProductsLoading(false);
  //   }
  // };
  const handleCategoryClick = async (index, id) => {
    setsearch('')
    setActiveIndex(index);
    setProductsLoading(true);

    try {
      if (id === "fav") {
        setcategoryid(0);
        setisfav(true);
        await getProductData(0, true, '', '');
      } else {
        setisfav(false);
        // console.log("called", index, id);
        setcategoryid(id);
        await getProductData(id, false, '', 'TEXT');
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setProductsLoading(false);
    }
  };

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
    setIsScrollAtStart(scrollLeft === 0);
    setIsScrollAtEnd(scrollLeft + clientWidth === scrollWidth);
  };

  const scrollToPrevItem = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollToNextItem = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const [isOrderButtonVisible, setIsOrderButtonVisible] = useState(false);

  useEffect(() => {
    setIsOrderButtonVisible(Object.keys(localProducts).length > 0);
  }, [localProducts]);

  // const getProducts = (val, searchBy) => {
  //   dispatch(searchProduct(val, searchBy));
  // };

  // Debounce function for text search
  // const searchfunction = debounce((val) => {
  //   getProducts(val, 'TEXT');
  // }, 500);

  // UseEffect to handle barcode search
  // useEffect(() => {
  //   if (barcode) {
  //     getProducts(barcode, 'BARCODE');
  //   }
  // }, [barcode]);

  // useEffect(() => {
  //   if (search.length >= 4) {
  //     searchfunction(search);
  //   }
  // }, [search]);
  // console.log("SEARCH", search);
  return (
    <>
      {OpenCashModal && (
        <OpeningCash_Modal
          onClose={() => {
            dispatch(sessionstatesave(false));
          }}
        />
      )}

      <div className="grid grid-cols-3 bg-[#EEEEEE] mt-2 gap-0 mr-5 ">
        {/* <div className=""> */}
        <ProductBox
          returnProducts={returnProducts}
          draftOrder={draftOrder}
          products={localProducts}
          onRemoveProduct={handleRemoveProduct}
          onUpdateQuantity={handleUpdateQuantity}
          onClearCart={handleClearCart}
        />

        {/* </div> */}

        <div className="flex col-span-2 justify-end">
          <div className="flex flex-col w-full max-w-[96%]">
            <div className="flex flex-row justify-start gap-5">
              <div style={{ width: isOrderButtonVisible ? "80%" : "96%" }}>
                <ProductSearchBar
                  value={search}
                  onSearch={(val) => {
                    // console.log("val", val);

                    setsearch(val);
                    searchfunction(val);
                  }}
                  id="searchBar"
                />
              </div>
              <Link to="/orders" className="nav-link">
                <ReturnButton />
              </Link>
              {Object.keys(localProducts).length > 0 && <NewOrderButton onClick={handleNewOrderClick} />}
            </div>
            <div className="flex flex-row items-center ">
              {!isScrollAtStart && (
                <button
                  className="text-black text-l font-bold focus:outline-none hover:bg-gradient-to-l rounded-lg p-2"
                  onClick={scrollToPrevItem}
                >
                  {"<"}
                </button>
              )}
              <div
                className="flex overflow-x-auto scrollbar-hidden"
                ref={carouselRef}
                onScroll={handleScroll}
              >
                {categoriesLoading ? (
                  <CategorySkeleton />
                ) : (
                  categories.map((category, index) => (
                    <CategoryButton
                      key={index}
                      active={activeIndex === index}
                      onClick={() =>
                        handleCategoryClick(index, category?.main_category_id)
                      }
                    >
                      {category?.main_category_id === 'fav' ?
                        <img className="h-5 mb-0.5 w-auto justify-center items-center" src={star_icon} /> : <>{category?.name}</>
                      }
                    </CategoryButton>
                  ))
                )}
              </div>
              {!isScrollAtEnd && (
                <button
                  className="text-black- text-l font-bold focus:outline-none rounded-lg p-2"
                  onClick={scrollToNextItem}
                >
                  {">"}
                </button>
              )}
            </div>
            {productsLoading ? (
              <ProductGridSkeleton />
            ) : (
              <ProductGrid onSelectProduct={handleSelectProduct} products={products} />
            )}

            <BarcodeReader
              onScan={(data) => {
                // setsearch('');
                // console.log("barcode called===>", data)
                getProductData(0, false, data, 'BARCODE');
              }}
              onError={(err) => {
                console.error(err);
              }}
              facingMode="environment"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCo;
