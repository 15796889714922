import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseSessionModal } from "./index";
import {
  bell_icon_black,
  cart_icon,
  cart_icon_white,
  dashboard_icon,
  dashboard_icon_black,
  lang_icon_black,
  logo,
  logout_icon,
  session_icon_black,
  session_icon_white,
  user_image_icon,
} from "../assets";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { fetchDashboardData } from "../Redux/Action/dashboarddataAction";
import LanguageSwitcher from "./Modal/language-switcher";
import { useTranslation } from "react-i18next";
import LanguageChange_Modal from "./Modal/LanguageChange_Modal";
import { clearReturnProducts } from "../Redux/Action/returnproductAction";
import { clearProducts } from "../Redux/Action/productsAction";

const CloseSessionButton = ({ openModal }) => {
  const { t, i18n } = useTranslation();

  return (
    <button
      onClick={openModal}
      className="flex justify-center w-[100%] py-2.5 font-semibold bg-gradient-to-b from-red-400 via-red-500 to-red-500 text-white"
    >
      <img
        loading="lazy"
        src={logout_icon}
        alt="Logout Icon"
        className={`shrink-0 self-start mx-2 w-6 aspect-[0.96] mt-0.5 transition-transform transform ${
          i18n.language == "ar" ? "scale-x-[-1]" : ""
        }`}
      />
      <div className=" ml-1">{t("Close")}</div>
    </button>
  );
};


const Navbar = ({ closeSessionModal }) => {
  const { t, i18n } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingLanguage, setPendingLanguage] = useState(null);

  const [selectedItem, setSelectedItem] = useState("");
  const [userData, setUserData] = useState(null);
  const { user } = useSelector((state) => state?.login);
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;

  useEffect(() => {
    const match = pathname.substring(1, 12);
    console.log("SUB", match);
    if (match == "orders") {
      handleItemClick("Orders");
    } else if (match == "session") {
      handleItemClick("Session");
    } else if (match == "dashboard") {
      handleItemClick("Dashboard");
    }
  }, [location]);

  // const determineActiveSubheading = () => {
  //   for (let i = 0; i < subheadings.length; i++) {
  //     const subheadingsArray = subheadings[i];
  //     for (let j = 0; j < subheadingsArray.length; j++) {
  //       if (subheadingsArray[j].route === currentPage) {
  //         return { headingIndex: i, subheadingIndex: j };
  //       }
  //     }
  //   }
  //   return { headingIndex: null, subheadingIndex: null };
  // };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const [isCloseSessionModalOpen, setIsCloseSessionModalOpen] = useState(false);

  const openCloseSessionModal = () => {
    // dispatch(fetchDashboardData());

    setIsCloseSessionModalOpen(true);
  };

  const closeCloseSessionModal = () => {
    setIsCloseSessionModalOpen(false);
  };

  // const logout = async () => {
  //   const response = await axiosInstance.post(URLS.LOGOUT_API, {
  //     session_id: user?.pos_session_id,
  //     user_id: user?.user_id,
  //     amount_total: availableCash,
  //     counted_amount: countedCash,
  //     is_accept: true,
  //   });
  //   if (response?.data?.message == "Session closed successfully.") {
  //     localStorage.clear();
  //     window.location.reload();
  //   }
  //   console.log("logout called", response);
  // };

  const showModal = (option) => {
    setPendingLanguage(option);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (pendingLanguage && pendingLanguage.value !== i18n.language) {
      // await axiosInstance.post(URLS.CHANGE_LANGUAGE, {
      //   code: pendingLanguage.value,
      // });
      i18n.changeLanguage(pendingLanguage.value); 
      localStorage.setItem("selectedLanguage", pendingLanguage.value); 
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    dispatch(clearProducts());
    dispatch(clearReturnProducts())

    Object.keys(localStorage).forEach(key => {
      if (key.startsWith("draftOrder_")) {
        localStorage.removeItem(key);
      }
    });
    
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const shouldOpenModal = () => {
    return pendingLanguage && pendingLanguage.value !== i18n.language;
  };

  // const handleLangChange = async (option) => {
  //   if (option.value != i18n.language) {
  //     // await axiosInstance.post(URLS.CHANGE_LANGUAGE, {
  //     //   code: option.value,
  //     // });
  //     i18n.changeLanguage(option.value); // Change language using i18next
  //     localStorage.setItem("selectedLanguage", option.value); // Save selected language to localStorage
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //   }
  // };

  return (
    <>
      {isCloseSessionModalOpen && (
        <CloseSessionModal
          closeModal={closeCloseSessionModal}
          // handleClose={() => logout()}
        />
      )}
      <div className="navbar">
        <div className="flex flex-wrap gap-5 justify-between px-8 py-1.5 max-md:px-5 w-full">
          <div className="flex flex-wrap gap-5">
            <div className="flex gap-2.5 my-auto">
              <img
                loading="lazy"
                src={logo}
                alt="logo"
                className="shrink-0 aspect-[1.05] ml-auto w-[45px]"
              />
              <div className="text-3xl h-[25px] mt-2 font-semibold text-slate-800">
                POS
              </div>
            </div>
            <div className="flex flex-wrap gap-2 justify-center text-xl font-semibold tracking-wide capitalize whitespace-nowrap text-zinc-800">
              <Link to="/dashboard" className="nav-link">
                <div
                  className={`flex flex-col justify-center ${
                    selectedItem === "Dashboard"
                      ? "bg-[#CD9C00] rounded-xl"
                      : "rounded-xl bg-white bg-opacity-25"
                  }`}
                  onClick={() => handleItemClick("Dashboard")}
                >
                  <div className="flex flex-col justify-center px-5 py-5 w-full rounded-xl">
                    <div className="flex gap-2 items-center">
                      {selectedItem === "Dashboard" ? (
                        <img
                          loading="lazy"
                          src={dashboard_icon}
                          alt="Dashboard Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-white"
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={dashboard_icon_black}
                          alt="Dashboard Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-black"
                        />
                      )}
                      <div
                        className={`${
                          selectedItem === "Dashboard"
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {t("Dashboard")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/session" className="nav-link">
                <div
                  className={`flex flex-col justify-center ${
                    selectedItem === "Session"
                      ? "bg-[#CD9C00] rounded-xl"
                      : "rounded-xl bg-white bg-opacity-25"
                  }`}
                  onClick={() => handleItemClick("Session")}
                >
                  <div className="flex flex-col justify-center px-10 py-5 w-full rounded-xl">
                    <div className="flex gap-2 items-center">
                      {selectedItem === "Session" ? (
                        <img
                          loading="lazy"
                          src={session_icon_white}
                          alt="Session Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-white"
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={session_icon_black}
                          alt="Session Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-black"
                        />
                      )}
                      <div
                        className={`${
                          selectedItem === "Session"
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {t("Session")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/orders" className="nav-link">
                <div
                  className={`flex flex-col justify-center ${
                    selectedItem === "Orders"
                      ? "bg-[#CD9C00] rounded-xl"
                      : "rounded-xl bg-white bg-opacity-25"
                  }`}
                  onClick={() => handleItemClick("Orders")}
                >
                  <div className="flex flex-col justify-center px-11 py-5 w-full rounded-xl">
                    <div className="flex gap-2 items-center">
                      {selectedItem === "Orders" ? (
                        <img
                          loading="lazy"
                          src={cart_icon_white}
                          alt="Cart Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-white"
                        />
                      ) : (
                        <img
                          loading="lazy"
                          src={cart_icon}
                          alt="Cart Icon"
                          className="shrink-0 my-auto w-[22px] aspect-square fill-black"
                        />
                      )}
                      <div
                        className={`${
                          selectedItem === "Orders"
                            ? "text-white"
                            : "text-black"
                        }`}
                      >
                        {t("Orders")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex gap-5 justify-between items-center my-auto text-lg">
          <div className="">
              <LanguageSwitcher handleLangChange={showModal} />
            </div>
            <LanguageChange_Modal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              shouldOpenModal={shouldOpenModal}
            />
            {/* <img
              loading="lazy"
              src={lang_icon_black}
              alt="Lang Icon"
              className="shrink-0 my-auto w-8 h-8 aspect-square"
            /> */}
            {/* <img
              loading="lazy"
              src={bell_icon_black}
              alt="Bell Icon"
              className="shrink-0 my-auto w-8 h-9  aspect-square"
            /> */}
            <div className="flex gap-5 justify-between items-center">
              <div className="flex items-center py-1.5 pr-4 pl-1.5 font-medium border border-solid bg-white bg-opacity-20 border-slate-200 rounded-[100px] text-zinc-800">
                <div className="flex gap-3 items-center">
                  {user && user?.user_logo ? (
                    <img
                      loading="lazy"
                      src={user?.user_logo}
                      alt="User Logo"
                      className="shrink-0 w-9 rounded-full aspect-square"
                    />
                  ) : (
                    <img
                      loading="lazy"
                      src={user_image_icon}
                      alt="user_default_icon"
                      className="shrink-0 w-9 rounded-full aspect-square"
                    />
                  )}
                  <div>{user ? user?.first_name : ""}</div>
                </div>
              </div>
              <div>
                <CloseSessionButton openModal={openCloseSessionModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
