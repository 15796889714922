// src/redux/reducers/customerReducer.js
import { FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILURE, SET_CUSTOMER, CLEAR_CUSTOMER } from '../Action/getcustomerAction';

const initialState = {
    customers: [],
    selectedCustomer: {},  
    loading: false,
    error: null
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.payload
            };
        case FETCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            case SET_CUSTOMER:
            return {
                // ...state,
                selectedCustomer: action.payload
            };
        case CLEAR_CUSTOMER:
            return {
                ...state,
                selectedCustomer: null
            };
        default:
            return state;
    }
};

export default customerReducer;