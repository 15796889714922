import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const loginUserRequest = () => ({ type: "LOGIN_USER_REQUEST" });
export const loginUserSuccess = (user) => ({
  type: "LOGIN_USER_SUCCESS",
  payload: user,
});
export const loginUserFailure = (error) => ({
  type: "LOGIN_USER_FAILURE",
  payload: error,
});

export const loginUser = (login, password) => async (dispatch) => {
  dispatch(loginUserRequest());
  try {
    const response = await axiosInstance.post(URLS.LOGIN_API_URL, {
      login: login,
      password: password,
    });
       
   if(response?.data?.message === "Logged-In Successfully") dispatch(loginUserSuccess(response?.data));
    return response
  } catch (error) {
    dispatch(loginUserFailure(error.message));
    return error
  }
};
