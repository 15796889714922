import { useSelector } from "react-redux";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { store } from "../store";


export const sessionidentifier = (val) => ({
  type: "PREVIOUS_SESSION_SUCCESS",
  payload: val,
});
//calling function
export const sessionstatesave = (val) => (dispatch) => {
  console.log("called");
  dispatch(sessionidentifier(val));
};

export const categorywisedata = (val) => ({
  type: "SEARCH_CATEGORY_DATA",
  payload: val,
});
//calling function
export const getcategorydata = (category_id,isfav,search_term,limit=40) => async(dispatch) => {
  console.log("called");

  try {
    const state = store.getState();
    const payload={
      store_id:state?.login?.user?.store_id,
      user_id:state?.login?.user?.user_id,
      category_id:category_id,
      isfav:isfav,
      search_term:search_term,
      limit:limit
    }
    const response=await axiosInstance.post(URLS.GET_PRODUCT_API,payload)
    console.log("called",response);
    if(response?.data?.status_code === 200 )dispatch(categorywisedata(response?.data?.result));
  } catch (error) {
    console.error(error)
  }
  return;
};

// const response=await axiosInstance.post(URLS.GET_PRODUCT_API,payload)
// if(response?.data?.status_code === 200 && barcode !== "" && response?.data?.result?.length == 1){dispatch(categorywisedata(response?.data?.result));
//   console.log("BARCODE",barcode );
//   dispatch(addProducts(response?.data?.result?.[0]))
// }
// console.log("called",response);
// if(response?.data?.status_code === 200 )dispatch(categorywisedata(response?.data?.result));
// } catch (error) {
// console.error(error)
// }
// return;
// };
