import { URLS } from "../../API/API endpoints";
import axiosInstance from '../../AxiosInstance';
import { store } from "../store";

export const FETCH_ORDER_RECEIPT_REQUEST = 'FETCH_ORDER_RECEIPT_REQUEST';
export const FETCH_ORDER_RECEIPT_SUCCESS = 'FETCH_ORDER_RECEIPT_SUCCESS';
export const FETCH_ORDER_RECEIPT_FAILURE = 'FETCH_ORDER_RECEIPT_FAILURE';

const fetchOrderReceiptRequest = () => ({
    type: FETCH_ORDER_RECEIPT_REQUEST,
});

const fetchOrderReceiptSuccess = (order) => ({
    type: FETCH_ORDER_RECEIPT_SUCCESS,
    payload: order,
});

const fetchOrderReceiptFailure = (error) => ({
    type: FETCH_ORDER_RECEIPT_FAILURE,
    payload: error,
});

export const fetchOrderReceipt = (store_id,order_id) =>  async (dispatch) => {
        if (!store_id) {
            dispatch(fetchOrderReceiptFailure("Store ID not found"));
            return;
        }

        // const orderId = parseInt(order_id)
        dispatch(fetchOrderReceiptRequest());
        axiosInstance.post(URLS.GET_ORDER_RECEIPT, { store_id, order_id })
            .then(response => {
                dispatch(fetchOrderReceiptSuccess(response.data.Order));
                console.log("response.data.Order",response.data.Order)
                return response.data.Order
            })
            .catch(error => {
                dispatch(fetchOrderReceiptFailure(error.message));
            });
    };