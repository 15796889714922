import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { add_customer_icon, customers_search_icon } from '../../../assets';
import AddCustomer_Modal from '../../Modal/AddCustomer_Modal';
import { fetchCustomers, setCustomer } from '../../../Redux/Action/getcustomerAction';
import { useTranslation } from 'react-i18next';

export const CustomerSearchBar = ({ onCustomerSelect }) => {
    const { t, i18n } = useTranslation();
    const isRtl = i18n.dir() === 'rtl';

    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const { selectedCustomer } = useSelector((state) => state?.getcustomer);


    const dispatch = useDispatch();
    const { customers, loading, error } = useSelector((state) => state.getcustomer);

    const openModal = () => setIsAddCustomerModalOpen(true);
    const closeModal = () => {
        setIsAddCustomerModalOpen(false);
    };

    useEffect(() => {
        if (selectedCustomer != null) {
            setSearchValue(selectedCustomer?.customer?.phone);
        }
    },[]);

    useEffect(() => {
        if (highlightedIndex >= 0 && highlightedIndex < (customers || []).length) {
            setSearchValue(customers[highlightedIndex].phone);
        }
    }, [highlightedIndex, customers]);

    const handleInputChange = (e) => {
        onCustomerSelect(false);
        const value = e.target.value;
        setSearchValue(value);
        const limit = 5;
        const offset = 5;
        dispatch(fetchCustomers(String(value), limit, offset));
        setIsDropdownOpen(value.length > 0);
        setHighlightedIndex(-1);
    };

    const handleCustomerSelect = (customer) => {
        setSearchValue(customer.phone);
        setIsDropdownOpen(false);
        onCustomerSelect(true);
        dispatch(setCustomer({ customer }));

    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, (customers || []).length - 1));
        } else if (e.key === "ArrowUp") {
            setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter" && highlightedIndex >= 0) {
            handleCustomerSelect(customers[highlightedIndex]);
            setHighlightedIndex(-1);
        }
    };

    // console.log("Error: ", error);
    // console.log("Loading: ", loading);
    // console.log("Customer: ", customers);

    return (
        <>
            {isAddCustomerModalOpen && <AddCustomer_Modal onClose={closeModal} />}
            <div className="relative flex ml-8 w-[100%]">
                {isRtl ? (
                    <button onClick={openModal} className="absolute inset-y-0 left-0 flex items-center pl-3 w-10">
                        <img src={add_customer_icon} alt="add_customer_icon" />
                    </button>
                ) : (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <img src={customers_search_icon} alt="customers_search_icon" />
                    </span>
                )}
                <input
                    type="text"
                    placeholder={t("Search customer phone number")}
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className="border p-2 pl-10 pr-10 rounded w-full focus:outline-none"
                />
                {isRtl ? (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <img src={customers_search_icon} alt="customers_search_icon" />
                    </span>
                ) : (
                    <button onClick={openModal} className="absolute inset-y-0 right-0 flex items-center pr-2 w-10">
                        <img src={add_customer_icon} alt="add_customer_icon" />
                    </button>
                )}
                {isDropdownOpen && (
                    <div className="absolute top-full left-0 right-0 bg-white border border-gray-300 rounded shadow-lg z-10">
                        {loading && <div className="p-2">Loading...</div>}
                        {error && <div className="p-2 text-red-500">Error: {error}</div>}
                        {(customers || []).map((customer, index) => (
                            <div
                                key={index}
                                onClick={() => handleCustomerSelect(customer)}
                                className={`p-2 cursor-pointer border hover:bg-gray-100 ${highlightedIndex === index ? "bg-gray-200" : ""}`}
                            >
                                {customer.phone}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

// export const CustomerSearchBar = ({ onCustomerSelect }) => {
//     const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
//     const [searchValue, setSearchValue] = useState("");
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [filteredCustomers, setFilteredCustomers] = useState(dummyCustomers);
//     const [highlightedIndex, setHighlightedIndex] = useState(-1);

//     const dispatch = useDispatch();
//     const { customers, loading, error } = useSelector((state) => state.customer);

//     const openModal = () => setIsAddCustomerModalOpen(true);
//     const closeModal = () => {
//         setIsAddCustomerModalOpen(false)
//         // getrg)()
//     };

//     useEffect(() => {
//         if (highlightedIndex >= 0 && highlightedIndex < filteredCustomers.length) {
//             setSearchValue(filteredCustomers[highlightedIndex].name);
//         }
//     }, [highlightedIndex, filteredCustomers]);

//     const handleInputChange = (e) => {
//         onCustomerSelect(false);
//         onCustomerSelect(false);
//         const value = e.target.value;
//         setSearchValue(value);
//         dispatch(fetchCustomers(value));
//         setFilteredCustomers(
//             dummyCustomers.filter((customer) =>
//                 customer.name.toLowerCase().includes(value.toLowerCase()) || 
//                 customer.number.includes(value)
//             )
//         );
//         setIsDropdownOpen(value.length > 0);
//         setHighlightedIndex(-1);
//     };

//     const handleCustomerSelect = (customer) => {
//         setSearchValue(customer.name);
//         setIsDropdownOpen(false);
//         onCustomerSelect(true);
//     };

//     const handleKeyDown = (e) => {
//         if (e.key === "ArrowDown") {
//             setHighlightedIndex((prevIndex) => Math.min(prevIndex + 1, filteredCustomers.length - 1));
//         } else if (e.key === "ArrowUp") {
//             setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//         } else if (e.key === "Enter" && highlightedIndex >= 0) {
//             handleCustomerSelect(filteredCustomers[highlightedIndex]);
//             setHighlightedIndex(-1);
//         }
//     };

//     return (
//         <>
//             {isAddCustomerModalOpen && <AddCustomer_Modal onClose={closeModal} />}
//             <div className="relative flex ml-8 w-[100%]">
//                 <span className="absolute inset-y-0 left-0 flex items-center pl-3">
//                     <img src={customers_search_icon} alt="customers_search_icon" />
//                 </span>
//                 <input
//                     type="text"
//                     placeholder="Search customer name/number"
//                     value={searchValue}
//                     onChange={handleInputChange}
//                     onKeyDown={handleKeyDown}
//                     className="border p-2 pl-10 pr-10 rounded w-full focus:outline-none"
//                 />
//                 <button onClick={openModal} className="absolute inset-y-0 right-0 flex items-center pr-2 w-10">
//                     <img src={add_customer_icon} alt="right_icon" />
//                 </button>
//                 {isDropdownOpen && (
//                     <div className="absolute top-full left-0 right-0 bg-white border border-gray-300 rounded shadow-lg z-10">
//                         {filteredCustomers.map((customer, index) => (
//                             <div
//                                 key={customer.id}
//                                 onClick={() => handleCustomerSelect(customer)}
//                                 className={`p-2 cursor-pointer border  hover:bg-gray-100 ${highlightedIndex === index ? "bg-gray-200" : ""}`}
//                             >
//                                 {customer.name} - {customer.number}
//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </div>
//         </>
//     );
// };
