import * as React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { useSelector, useDispatch } from "react-redux";
import {
  bank_method_icon,
  cash_method_icon,
  clear_icon,
  return_icon,
} from "../../assets";
import { createOrder } from "../../Redux/Action/createposorderAction";
import { refundOrder } from "../../Redux/Action/createposrefundorderAction";
import { useTranslation } from "react-i18next";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

function PaymentButton({ paymentMethod, selected, onClick }) {
  const { t, i18n } = useTranslation();

  const getIcon = (method) => {
    switch (method) {
      case "Cash":
        return cash_method_icon;
      case "Card":
        return bank_method_icon;
      default:
        return null;
    }
  };

  return (
    <div
      className={`flex items-center justify-center gap-2 mt-8 px-10 py-1 text-l font-semibold tracking-wide rounded-lg border-2 border-solid shadow-sm basis-auto grow-0 max-md:px-5 cursor-pointer ${
        selected
          ? "text-blue-900 bg-white border-blue-900"
          : "bg-white border border-solid border-stone-300 text-zinc-600"
      }`}
      onClick={onClick}
    >
      {getIcon(paymentMethod.name) && (
        <img
          src={getIcon(paymentMethod.name)}
          alt={`${paymentMethod.name} icon`}
          className="shrink-1 ml-0 w-auto object"
        />
      )}
      <div className="">{t(paymentMethod.name)}</div>
    </div>
  );
}

function KeypadButton({
  value,
  className,
  onClick,
  children,
  isBankPaymentSelected,
}) {
  return (
    <button
      type="button"
      className={`flex justify-center items-center h-20 w-40 rounded ${
        isBankPaymentSelected ? "opacity-50" : ""
      } ${className}`}
      onClick={isBankPaymentSelected ? null : onClick}
      disabled={isBankPaymentSelected}
    >
      {children || value}
    </button>
  );
}

function Payment_Modal({ onClose, grandTotal, localProducts }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";

  const { user } = useSelector((state) => state?.login);
  const { selectedCustomer } = useSelector((state) => state?.getcustomer);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user_id = user?.user_id;
  const store_id = user?.store_id;
  const pos_session_id = user?.pos_session_id;
  const user_email = user?.email;
  const customer_id = selectedCustomer?.customer?.customer_id;
  const customer_mobile = selectedCustomer?.customer?.phone;

  const handleCloseModal = () => {
    onClose();
  };

  const [totalPaid, setTotalPaid] = useState("");
  const [change, setChange] = useState("");
  // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    id: 2,
    name: "Cash",
  });
  const [isBankPaymentSelected, setIsBankPaymentSelected] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  // const { selectedCustomer } = useSelector((state) => state?.selectedCustomer);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await axiosInstance.post(URLS.GET_PAYMENT_METHOD, {
          user_id: user?.user_id,
        });
        if (response?.data?.result?.status_code === 200) {
          setPaymentMethods(response.data.result.data);
        } else {
          console.error(
            "Error fetching payment methods:",
            response?.data?.result?.status
          );
        }
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    if (grandTotal < 0) {
      setTotalPaid("0");
      setChange(grandTotal.toString());
    }
  }, [grandTotal]);

  const keypadValues = [
    [
      { value: "1", className: "bg-gray-100" },
      { value: "2", className: "bg-gray-100" },
      { value: "3", className: "bg-gray-100" },
    ],

    [
      { value: "4", className: "bg-gray-100" },
      { value: "5", className: "bg-gray-100" },
      { value: "6", className: "bg-gray-100" },
    ],

    [
      { value: "7", className: "bg-gray-100" },
      { value: "8", className: "bg-gray-100" },
      { value: "9", className: "bg-gray-100" },
    ],

    [
      { value: ".", className: "bg-gray-100" },
      { value: "0", className: "bg-gray-100" },
      {
        value: "C",
        className: "bg-gray-100",
        isIcon: true,
        icon: clear_icon,
      },
    ],
    [
      { value: "50", className: "bg-gray-100 text-l" },
      { value: "100", className: "bg-gray-100" },
      { value: "200", className: "bg-gray-100" },
    ],
  ];

  const handleKeypadButtonClick = (value) => {
    if (
      totalPaid.length >= 10 &&
      value !== "C" &&
      value !== "." &&
      !["50", "100", "200"].includes(value)
    ) {
      return;
    }

    if (value === "C") {
      setTotalPaid("");
      setChange("");
    } else if (value === ".") {
      if (!totalPaid.includes(".")) {
        setTotalPaid((prevTotalPaid) => prevTotalPaid + ".");
      }
    } else if (!isNaN(parseInt(value, 10))) {
      if (["50", "100", "200"].includes(value)) {
        const updatedTotalPaid = parseFloat(totalPaid || 0) + parseFloat(value);
        setTotalPaid(updatedTotalPaid.toString());
        const changeNumeric =
          updatedTotalPaid -
          parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
        setChange(`${Math.max(changeNumeric, 0)}`);
      } else {
        const updatedTotalPaid = totalPaid + value;
        setTotalPaid(updatedTotalPaid);
        const numericValue = parseFloat(
          updatedTotalPaid.replace(/[^0-9.]/g, "")
        );
        const changeNumeric =
          numericValue - parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
        setChange(`${Math.max(changeNumeric, 0)}`);
      }
    }
    setErrorMessage("");
  };

  // const handleKeypadButtonClick = (value) => {
  //     if (totalPaid.length >= 10 && value !== "C" && value !== ".") {
  //         return;
  //     }

  //     if (value === "C") {
  //         setTotalPaid("");
  //         setChange("");
  //     } else if (value === ".") {
  //         if (!totalPaid.includes(".")) {
  //             setTotalPaid((prevTotalPaid) => prevTotalPaid + ".");
  //             const numericValue = parseFloat(totalPaid.replace(/[^0-9.]/g, ""));
  //             const changeNumeric = numericValue - parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
  //             setChange(`${Math.max(changeNumeric, 0)}`); // Ensure change is not negative
  //         }
  //     } else if (!isNaN(parseInt(value, 10))) {
  //         const updatedTotalPaid = totalPaid + value;
  //         setTotalPaid(updatedTotalPaid);

  //         if (updatedTotalPaid.trim() === "") {
  //             setChange("");
  //         } else {
  //             const numericValue = parseFloat(updatedTotalPaid.replace(/[^0-9.]/g, ""));
  //             const changeNumeric = numericValue - parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
  //             setChange(`${Math.max(changeNumeric, 0)}`); // Ensure change is not negative
  //         }
  //     }
  //     setErrorMessage("");
  // };

  const handlePaymentMethodClick = (method) => {
    setSelectedPaymentMethod(method);
    if (method.name === "Card") {
      setIsBankPaymentSelected(true);
      setTotalPaid(String(grandTotal));
      setChange("0");
    } else {
      setIsBankPaymentSelected(false);
      setTotalPaid("");
      setChange("");
    }
    setErrorMessage("");
  };

  const handleCheckoutClick = async (event) => {
    if (!totalPaid) {
      event.preventDefault();
      setErrorMessage("Please enter total paid amount.");
      return;
    }

    if (totalPaid < grandTotal) {
      event.preventDefault();
      setErrorMessage("Total Paid amount can not be less than Grand Total.");
      return;
    }

    if (!selectedPaymentMethod) {
      event.preventDefault();
      setErrorMessage("Please select a payment method.");
      return;
    }

    setLoading(true);

    const order_id = new URLSearchParams(window.location.search).get(
      "refundorder"
    );

    // console.log("ORDER ID OF REFUND", order_id);

    const returnProducts = localProducts.filter((product) => product.isReturn);
    const newProducts = localProducts.filter((product) => !product.isReturn);

    const createOrderPayload = {
      user_id,
      user_email,
      customer_id,
      customer_mobile,
      store_id,
      invoice: {
        bill_amount: grandTotal,
        amount_paid: parseFloat(totalPaid),
        amount_return: parseFloat(change),
      },
      data: {
        pos_session_id,
        payment_method_id: selectedPaymentMethod.id,
        order_lines: newProducts.map((product) => ({
          variant_id: product.product_id,
          variant_name: product.product_name,
          uom_id: product.uom_id,
          order_qty: product.quantity,
          sell_price: product.price,
          total_amount: product.price * product.quantity,
        })),
      },
    };

    const refundOrderPayload = {
      order_id: parseInt(order_id, 10),
      user_id,
      bill_amount: grandTotal,
      amount_paid: parseFloat(totalPaid),
      amount_return: parseFloat(change),
      lines: returnProducts.map((product) => ({
        product_id: product.product_id,
        variant_name: product.product_name,
        qty: product.quantity,
        unit_price: product.rate,
        total_amount: product.rate * product.quantity,
      })),
      new_lines: newProducts.map((product) => ({
        product_id: product.product_id,
        variant_name: product.product_name,
        qty: product.quantity,
        price_unit: product.price,
      })),
    };

    const finalPayload = {
      payload: refundOrderPayload,
    };

    try {
      let response;
      let createdOrderId;
      if (returnProducts.length > 0) {
        response = await dispatch(refundOrder(finalPayload));
        createdOrderId = response?.new_pos_order_id;
        navigate(`/OrderCheckout?order_id=${createdOrderId}`);
      } else {
        response = await dispatch(createOrder(createOrderPayload));
        createdOrderId = response?.result?.order_id;
        navigate(`/OrderCheckout?order_id=${createdOrderId}`);
      }
    } catch (error) {
      console.error("Error processing order:", error);
    } finally {
      setLoading(false);
    }
  };

  const totalPaidRef = React.useRef(null);

  useEffect(() => {
    if (totalPaidRef.current) {
      totalPaidRef.current.focus();
    }
  }, []);

  const handleKeyDown = (event) => {
    const { key } = event;
    if (!isNaN(key)) {
      if (totalPaid.length < 10) {
        setTotalPaid((prevTotalPaid) => {
          const updatedTotalPaid = prevTotalPaid + key;
          const numericValue = parseFloat(
            updatedTotalPaid.replace(/[^0-9.]/g, "")
          );
          const changeNumeric =
            numericValue -
            parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
          setChange(`${Math.max(changeNumeric, 0)}`);
          return updatedTotalPaid;
        });
      }
    } else if (key === "." && !totalPaid.includes(".")) {
      setTotalPaid((prevTotalPaid) => prevTotalPaid + ".");
    } else if (key === "Backspace") {
      setTotalPaid((prevTotalPaid) => {
        const updatedTotalPaid = prevTotalPaid.slice(0, -1);
        if (updatedTotalPaid === "") {
          setChange("");
        } else {
          const numericValue = parseFloat(
            updatedTotalPaid.replace(/[^0-9.]/g, "")
          );
          const changeNumeric =
            numericValue -
            parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
          setChange(`${Math.max(changeNumeric, 0)}`);
        }
        return updatedTotalPaid;
      });
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      {loading && <LoadingSpinner />}

      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className="relative flex flex-col px-12 py-16 text-lg font-semibold  bg-white rounded-[20px] text-zinc-800 max-md:px-5 z-40"
        style={{ width: "800px" }}
      >
        <button
          type="button"
          onClick={onClose}
          className="absolute top-4 right-3 text-zinc-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <main>
          <div className="flex flex-1 gap-10 max-md:flex-col">
            <div className="flex flex-col w-1/2 max-md:w-full">
              <div className="flex flex-col gap-2">
                <div className="text-xl font-semibold tracking-wider text-zinc-700">
                  {t("Grand Total")}
                </div>
                <div
                  className={`flex bg-white rounded-lg border border-solid border-stone-300 h-[60px] overflow-hidden justify-center items-start ${
                    i18n.language === "ar" ? "flex-row-reverse" : ""
                  }`}
                >
                  <span className="inset-y-0 flex items-center pl-3 py-4 text-xl text-gray-400">
                    {user?.currency}
                  </span>
                  <input
                    type="text"
                    className="bg-white text-left h-[60px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                    value={`${grandTotal}`}
                    style={{ direction: "ltr" }}
                    readOnly
                  />
                </div>

                <div className="text-xl font-bold mt-5 tracking-wider text-zinc-700 mt-2">
                  {t("Total Paid")}
                </div>
                <div
                  className={`flex bg-white rounded-lg border border-solid border-stone-300 h-[60px] overflow-hidden justify-center items-start ${
                    i18n.language === "ar" ? "flex-row-reverse" : ""
                  }`}
                >
                  <span className="inset-y-0 flex items-center pl-3 py-4 text-xl text-gray-400">
                    {user?.currency}
                  </span>
                  <input
                    ref={totalPaidRef}
                    type="text"
                    style={{ direction: "ltr" }}
                    className="bg-white text-left h-[60px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                    value={totalPaid === "" ? "" : `${totalPaid}`}
                    readOnly={isBankPaymentSelected}
                    onKeyDown={handleKeyDown}
                  />
                  {/* <span
                    className={`absolute inset-y-0 ${
                      isRtl ? "right-0" : "left-0"
                    } flex items-center pr-3 pl-3 text-gray-400`}
                  >
                    {user?.currency}
                  </span> */}
                </div>

                <div className="text-xl font-bold mt-5 tracking-wider text-zinc-700 mt-2">
                  {t("Change")}
                </div>
                <div
                  className={`flex bg-white rounded-lg border border-solid border-stone-300 h-[60px] overflow-hidden justify-center items-start ${
                    i18n.language === "ar" ? "flex-row-reverse" : ""
                  }`}
                >
                  <span className="inset-y-0 flex items-center pl-3 py-4 text-xl text-gray-400">
                    {user?.currency}
                  </span>
                  <input
                    type="text"
                    className="bg-white text-left h-[60px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                    value={change}
                    style={{ direction: "ltr" }}
                    readOnly
                  />
                  {/* <span
                    className={`absolute inset-y-0 ${
                      isRtl ? "right-0" : "left-0"
                    } flex items-center pr-3 pl-3 text-gray-400`}
                  >
                    {user?.currency}
                  </span> */}
                </div>
              </div>
              <div className="flex flex-row gap-5 mt-1 capitalize max-md:flex-wrap">
                {paymentMethods.map((method) => (
                  <PaymentButton
                    key={method.id}
                    paymentMethod={method}
                    selected={selectedPaymentMethod?.id === method.id}
                    onClick={() => handlePaymentMethodClick(method)}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col w-1/2 text-4xl font-bold tracking-wider text-center capitalize text-zinc-800 max-md:mt-7 max-md:w-full">
              {keypadValues.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className="flex gap-2 mb-3 max-md:flex-wrap max-md:w-full"
                >
                  {row.map((value, index) => (
                    <KeypadButton
                      key={index}
                      value={value.value}
                      className={value.className}
                      onClick={() => handleKeypadButtonClick(value.value)}
                      isBankPaymentSelected={isBankPaymentSelected}
                      style={value.isIcon ? { padding: "0.1rem" } : null}
                    >
                      {value.isIcon ? (
                        <img
                          src={value.icon}
                          alt="icon"
                          style={{ height: "1.5rem", width: "auto" }}
                        />
                      ) : (
                        value.value
                      )}
                    </KeypadButton>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </main>
        <footer>
          {errorMessage && (
            <div className="text-red-500 text-center mt-8">{errorMessage}</div>
          )}
          {/* {loading && (
                        <div className="text-center mt-8">Loading...</div>
                    )} */}
          <div className="nav-link">
            <button
              className="justify-center items-center px-16 py-5 mt-10 text-xl text-center text-white capitalize whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full "
              style={{ width: "700px" }}
              onClick={handleCheckoutClick}
            >
              {t("Checkout")}
            </button>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default Payment_Modal;
