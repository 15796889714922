import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { store } from "../store";
import { search_icon } from "../../assets";

export const getStoreOrdersRequest = () => ({
  type: "GET_STORE_ORDERS_REQUEST",
});

export const getStoreOrdersSuccess = (orders) => ({
  type: "GET_STORE_ORDERS_SUCCESS",
  payload: orders,
});

export const getStoreOrdersFailure = (error) => ({
  type: "GET_STORE_ORDERS_FAILURE",
  payload: error,
});

export const setOrderSearchTerm = (searchTerm) => ({
  type: 'SET_ORDER_SEARCH_TERM',
  payload: searchTerm,
});

export const fetchStoreOrders = (additionalPayload, search) => async (dispatch) => {
  const state = store.getState();
  dispatch(getStoreOrdersRequest());

  try {
    const response = await axiosInstance.post(URLS.GET_STORE_ORDERS, {
      store_id: state?.login?.user?.store_id,
      user_id: state?.login?.user?.user_id,
      order_state: state?.order?.order_state || 'all',
      search_term: search || '', 
      ...additionalPayload,
    });
    console.log("orders response", response);
    if (response?.data?.status === 200) {
      dispatch(getStoreOrdersSuccess(response.data.Orders));
    } else {
      dispatch(getStoreOrdersFailure("Failed to fetch orders"));
    }
  } catch (error) {
    dispatch(getStoreOrdersFailure(error.message));
  }
};

// export const getStoreOrdersRequest = () => ({
//   type: "GET_STORE_ORDERS_REQUEST",
// });

// export const getStoreOrdersSuccess = (orders) => ({
//   type: "GET_STORE_ORDERS_SUCCESS",
//   payload: orders,
// });

// export const getStoreOrdersFailure = (error) => ({
//   type: "GET_STORE_ORDERS_FAILURE",
//   payload: error,
// });

// export const setOrderSearchTerm = (searchTerm) => ({
//   type: "SET_ORDER_SEARCH_TERM",
//   payload: searchTerm,
// });

// export const fetchStoreOrders = (additionalPayload = {}) => async (dispatch) => {
//   const state = store.getState();
//   dispatch(getStoreOrdersRequest());

//   try {
//     const response = await axiosInstance.post(URLS.GET_STORE_ORDERS, {
//       store_id: state?.login?.user?.store_id,
//       user_id: state?.login?.user?.user_id,
//       ...additionalPayload,
//     });
//     console.log("orders response", response);
//     if (response?.data?.status === 200) {
//       dispatch(getStoreOrdersSuccess(response.data.Orders));
//     } else {
//       dispatch(getStoreOrdersFailure("Failed to fetch orders"));
//     }
//   } catch (error) {
//     dispatch(getStoreOrdersFailure(error.message));
//   }
// };

// export const getStoreOrdersRequest = () => ({
//   type: "GET_STORE_ORDERS_REQUEST",
// });

// export const getStoreOrdersSuccess = (orders) => ({
//   type: "GET_STORE_ORDERS_SUCCESS",
//   payload: orders,
// });

// export const getStoreOrdersFailure = (error) => ({
//   type: "GET_STORE_ORDERS_FAILURE",
//   payload: error,
// });

// export const fetchStoreOrders = (order_state) => async (dispatch) => {
//   const state = store.getState();
//   dispatch(getStoreOrdersRequest());

//   try {
//     const response = await axiosInstance.post(URLS.GET_STORE_ORDERS, {
//       store_id: state?.login?.user?.store_id,
//       user_id:state?.login?.user?.user_id,
//       order_state,
//     });
//     console.log("orders response",response)
//     if (response?.data?.status === 200) {
//       dispatch(getStoreOrdersSuccess(response.data.
//         Orders
//         ));
//     } else {
//       dispatch(getStoreOrdersFailure("Failed to fetch orders"));
//     }
//   } catch (error) {
//     dispatch(getStoreOrdersFailure(error.message));
//   }
// };
