export const ADD_RETURN_PRODUCTS = 'ADD_RETURN_PRODUCTS';
export const CLEAR_RETURN_PRODUCTS = 'CLEAR_RETURN_PRODUCTS';

export const addReturnProducts = (products) => ({
  type: ADD_RETURN_PRODUCTS,
  payload: products,
});

export const clearReturnProducts = () => ({
  type: ADD_RETURN_PRODUCTS,
});


