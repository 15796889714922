export const URLS = {
  //Login,Signup and Logout Api
  LOGIN_API_URL: "Login",
  LOGOUT_API:"posCloseSession",

  //Get Categories
  GET_CATEGORIES: "GetStoreCategories",

  //Get Product API
  GET_PRODUCT_API:"Get-Category-wise-product",

  //orders apis
  GET_STORE_ORDERS:"GetStoreOrders",

  // Add favourite product API 
  UPDATE_FAV_PRODUCT: "update-fav-product",
  

  // Customer APIs
  GET_CUSTOMERS: "get-customer",
  CREATE_CUSTOMER: "CreateCustomer",

  // Payment Method API 
  GET_PAYMENT_METHOD: "PaymentMethod",

  // Opening Cash API 
  OPENING_CASH_API: "OpeningCash",

  // View Order Details API 
  GET_POS_ORDER_LINES: "posOrderLines",

  // Create POS Order 
  CREATE_POS_ORDER: "CreateposOrder",

  // Order Receipt API
  GET_ORDER_RECEIPT: "GetOrderReceipt",

  // Dashboard Data 
  GET_DASHBOARD_DATA: "Get-Dashboard-Data",

  // Cash In & Cash Out 
  CASH_IN_OUT: "CashInOut",
  GET_CASH_IN_OUT:"GetCashInCashout" ,

  // Refund or Return Order API
  CREATE_REFUND_ORDER: "posRefundOrder"


};
