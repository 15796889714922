import React, { useState } from "react";
import { barcode_icon, search_icon } from "../../../assets";
import { useTranslation } from "react-i18next";

const ProductSearchBar = ({ onSearch, value }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === 'rtl';

  const [search, setsearch] = useState("");

  return (
    <div className="relative flex">
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        {isRtl ? (
          <img src={barcode_icon} alt="barcode_icon" />
        ) : (
          <img src={search_icon} alt="search_icon" />
        )}
      </span>
      <input
        value={value}
        onChange={(e) => {
          setsearch(e.target.value);
          onSearch(e.target.value);
        }}
        type="text"
        placeholder={t("Search product")}
        className={`border p-2 ${isRtl ? 'pr-10' : 'pl-10 pr-10'} rounded focus:outline-none w-[100%]`}
      />
      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
        {isRtl ? (
          <img src={search_icon} alt="search_icon" />
        ) : (
          <img src={barcode_icon} alt="barcode_icon" />
        )}
      </span>
    </div>
  );
};

export default ProductSearchBar;
