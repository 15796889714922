import { shop_logo } from "../../../assets";

function OrderInvoicePDF(order, t, user) {

  console.log("USER->", user);
  let htmlProductLines = "";

  if (order?.order_lines) {
    order?.order_lines?.map((item, index) => {
      htmlProductLines += `
        <tr key=${index}>
          <td style="text-align: center; vertical-align: text-top;">${index + 1}</td>
          <td style="text-align: left;">${item?.product_name}</td>
          <td style="text-align: end;">${item?.order_qty}</td>
          <td style="text-align: end;">${(item?.total_amount / item.order_qty)}</td>
          <td style="text-align: end;">${item?.total_amount}</td>
        </tr>
      `;
    });
  }


  let Change = order.amount_return;
  let Cash = order.amount_paid;
  let Cashier = user?.first_name;
  let store_logo = user?.store_logo;
  let store_address = user?.address;
  let store_mobile = user?.retailermobile;
  let store_email = user?.emailid;

  console.log("CASHIER----->", Cashier);

  const htmlContent = `
<!DOCTYPE html>
<html>
<head>
  <style>
  body {
    font-family: Arial, sans-serif;
    width: 100%;
    color: #000000;
    font-size: 10px;
  }
  .pos-receipt {
    text-align: center;
    padding: 0 20px; 
  }
  .pos-receipt-contact {
    margin-top: 5px;
  }
  .pos-receipt-contact .cashier {
    border-top: 1px dashed black;
  }
  .pos-receipt-contact table {
    width: 100%;
    margin-bottom: 5px;
  }
  .pos-receipt-contact table td:first-child {
    width: 100%;
  }
  .receipt-orderdata {
    width: 100%;
    font-size: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .receipt-orderdata th,
  .receipt-orderdata td {
    padding: 1px;
  }
  .receipt-orderdata td {
    font-size: 10px;
    text-align: center;
  }
  .pos-receipt-right-align {
    float: right;
  }
  .orderDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .orderDetail div, .cashierDetail {
    font-size: 10px;
  }
  .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
    margin-top: 5px;
  }
  .total div, .cashierDetail {
    font-size: 9px;
  }
  .lines {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .order-delivery {
    margin: 10px 0px;
  }
  .receiptHead {
    text-align: center;
    font-weight: semi-bold;
    font-size: 14px;
    margin: 10px 0px;
    width: 100%;
  }
  .pos-receipt-bottomSection {
    border-top: 1px dashed black;
    margin-top: 10px;
    font-size: 14px;
  }
  .bottomSection img {
    width: 100px;
  }
  .after-footer {
    margin-top: 0px;
  }
</style>
</head>
<body>
  <div class="pos-receipt-container">
    <div class="pos-receipt">
      <div style="text-align: center;">
        <img style="max-height: 50px; max-width: 40px;" src="${store_logo}" alt="My Barcode Image" width="100">
      </div>
      <div class="pos-receipt-contact">
      <div class="lines">  <strong>${t("Tel")}:</strong> ${store_mobile}</div>
            <div class="lines">  <strong>${t("Email")}:</strong> ${store_email}</div>
       <div class="lines" >${store_address}</div>
      </div>
      <div class="receiptHead">
        <strong>${t("Invoice")}</strong>
      </div>

      <div style="text-align: $ {i18n.language === 'ar' ? 'row-reverse' : 'row'};border-top: 1px dashed black;
      border-bottom: 1px dashed black; padding:5px 0px ;">

          <div class="orderDetail">
          <div class="lines">
            <strong>${t("Order No:")}</strong>
          </div>
          <div class="lines">
            ${order?.receipt_no}
          </div>
        </div>
        <div class="orderDetail">
          <div class="lines">
            <strong>${t("Order Date:")}</strong>
          </div>
          <div class="lines">
            ${order?.date}
          </div>
        </div>
        <div class="orderDetail">
          <div class="lines">
            <strong>${t("Cashier Name:")}</strong>
          </div>
          <div class="lines">
            ${Cashier}
          </div>
        </div>
        ${order?.customer_mobile !== "---" ? `
        <div class="orderDetail">
          <div class="lines">
            <strong>${t("Customer Phone:")}</strong>
          </div>
          <div class="lines">
            ${order?.customer_mobile}
          </div>
        </div>` : ""}
      </div>
      <div>
        <table class="receipt-orderdata">
          <colgroup>
            <col width="10%">
            <col width="40%">
            <col width="10%">
            <col width="10%">
            <col width="15%">
          </colgroup>
          <tr>
            <th>${t("SN")}.</th>
            <td style="text-align: left; font-weight: semi-bold; margin-top: 5px"><strong>${t("Description")}</strong></td>
            <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${t("Qty")}</strong></td>
            <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${t("Rate")}</strong></td>
            <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${t("Amount")}</strong></td>
          </tr>
          ${htmlProductLines}
        </table>
        <div class="total">
          <div><strong>${t("Total")}</strong></div>
          <div><strong>EGP ${order?.total_bill_amount}</strong></div>
        </div>
        <div class="total">
          <div><strong>${t("Amount Paid")}</strong></div>
          <div><strong>EGP ${Cash}</strong></div>
        </div>
        <div class="total">
          <div><strong>${t("Change")}</strong></div>
          <div><strong>EGP ${Change}</strong></div>
        </div>
        <br>
        <div class="after-footer"></div>
        <div style="display:inline; margin:0px 0px;">
          <img
            src="https://barcode.tec-it.com/barcode.ashx?data=${order?.receipt_no}&translate-esc=on"
            alt="Receipt Barcode" width="80%" height="50"/>
        </div>
        <div class="pos-receipt-bottomSection"><br>
          ${"Your Technology Partner"}<br>
          <img style="max-height: 50px; max-width: 100px;" src="https://live-production-assets.s3.me-central-1.amazonaws.com/myginne.webp" alt="myGinne Logo">
        </div>
      </div>
    </div>
  </div>
</body>
</html>
`;
  return htmlContent;
};

export default OrderInvoicePDF;
