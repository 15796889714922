import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { store } from "../store";


export const getDashboardDataRequest = () => ({
    type: "GET_DASHBOARD_DATA_REQUEST",
  });
  
  export const getDashboardDataSuccess = (data) => ({
    type: "GET_DASHBOARD_DATA_SUCCESS",
    payload: data,
  });
  
  export const getDashboardDataFailure = (error) => ({
    type: "GET_DASHBOARD_DATA_FAILURE",
    payload: error,
  });
  
  export const fetchDashboardData = () => async (dispatch) => {
    const state = store.getState();
    dispatch(getDashboardDataRequest());
  
    try {
      const response = await axiosInstance.post(URLS.GET_DASHBOARD_DATA, {
        session_id: state?.login?.user?.pos_session_id,
        user_id: state?.login?.user?.user_id,
      });
      console.log("Dashboard data response", response);
      if (response?.data?.status === 200) {
        dispatch(getDashboardDataSuccess(response.data));
      } else {
        dispatch(getDashboardDataFailure("Failed to fetch dashboard data"));
      }
    } catch (error) {
      dispatch(getDashboardDataFailure(error.message));
    }
  };