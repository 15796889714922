import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const CASH_IN_OUT_DATA_SUCCESS = 'CASH_IN_OUT_DATA_SUCCESS';
export const CASH_IN_OUT_DATA_FAILURE = 'CASH_IN_OUT_DATA_FAILURE';

export const cashInOutData = (sessionId, userId, type) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(URLS.GET_CASH_IN_OUT, {
        session_id: sessionId,
        user_id: userId,
        type,
      });

      if (response.status === 200) {
        dispatch(cashInOutDataSuccess(response.data.cash_in_data)); 
      } else {
        dispatch(cashInOutDataFailure('Failed to load data'));
      }
    } catch (error) {
      dispatch(cashInOutDataFailure(error.message));
    }
  };
};

export const cashInOutDataSuccess = (data) => ({
  type: CASH_IN_OUT_DATA_SUCCESS,
  payload: data
});

export const cashInOutDataFailure = (error) => ({
  type: CASH_IN_OUT_DATA_FAILURE,
  payload: error
});


// import axiosInstance from "../../AxiosInstance";
// import { URLS } from "../../API/API endpoints";

// export const CASH_IN_OUT_DATA_SUCCESS = 'CASH_IN_OUT_DATA_SUCCESS';
// export const CASH_IN_OUT_DATA_FAILURE = 'CASH_IN_OUT_DATA_FAILURE';

// export const cashInOutData = (sessionId, userId, type) => {
//   return async (dispatch) => {
//     try {
//       const response = await axiosInstance.post(URLS.GET_CASH_IN_OUT, {
//         session_id: sessionId,
//         user_id: userId,
//         type,
//       });

//       if (response.status === 200) {
//         dispatch(cashInOutDataSuccess(response.data));
//       } else {
//         dispatch(cashInOutDataFailure('Failed to load data'));
//       }
//     } catch (error) {
//       dispatch(cashInOutDataFailure(error.message));
//     }
//   };
// };

// export const cashInOutDataSuccess = (data) => ({
//   type: CASH_IN_OUT_DATA_SUCCESS,
//   payload: data
// });

// export const cashInOutDataFailure = (error) => ({
//   type: CASH_IN_OUT_DATA_FAILURE,
//   payload: error
// });
