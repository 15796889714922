import React, { useState } from "react";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCustomer } from "../../Redux/Action/getcustomerAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


function InputField({ label, placeholder, changed, isRtl, inputType }) {
  const [value, setValue] = useState("");

  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    let maxLength = 10;

    if (/^0/.test(inputValue)) {
      maxLength = 11;
    }

    if (/^\d*$/.test(inputValue) && inputValue.length <= maxLength) {
      setValue(inputValue);
      changed(inputValue);
    }
  };

  return (
    <>
      <div className="mt-9 font-medium max-md:max-w-full">{label}</div>
      <div className={`flex border border-solid rounded-xl overflow-hidden justify-center items-start ${inputType === 'number' && i18n.language === 'ar' ? 'flex-row-reverse' : ''}`}>
        {inputType === 'number' &&
          <span className="pl-3 py-3 bg-white  text-black max-md:pe-3">{i18n.language === 'ar' ? "20+" : "+20"}</span>
        }
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          style={{ direction:inputType === 'number' && 'ltr' }}
          onChange={handleChange}
          className={`p-3 bg-white rounded-xl  border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-1px ${inputType === 'number' ? 'text-left' : ''}`}
        />
        
      </div>
    </>
  );
}

function AddCustomer_Modal({ onClose }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === 'rtl';

  const dispatch = useDispatch();



  const [mobile, setMobile] = useState("");

  const { user } = useSelector((state) => state?.login);

  const handleConfirm = async () => {
    if (!mobile) {
      onClose();
      return;
    }
    if (mobile) {
      let formattedMobile = mobile;
      if (formattedMobile.startsWith("0")) {
        formattedMobile = formattedMobile.slice(1);
      }

      // Check if the mobile number is less than 10 digits
      if (formattedMobile.length < 10) {
        toast.error("Please check your number. It should be at least 10 digits long.");
        return;
      }

      try {
        const response = await axiosInstance.post(URLS.CREATE_CUSTOMER, {
          mobile: formattedMobile,
          user_id: user?.user_id,
        });
        console.log("Create customer response:", response);

        if (response.data.message === "Customer created successfully.") {
          let customer_id = response.data.customer_id
          let customer = { customer_id: customer_id, phone: mobile }
          dispatch(setCustomer({ customer }));
          onClose();
        } else if (response.data.message === "Customer with this mobile number already exists.") {
          // toast.error(t("Customer with this mobile number already exist."));
          onClose();
          return;
        }
      } catch (error) {
        console.error("Failed to create customer:", error);
      }

    }
  };

  // const handleConfirm = async () => {
  //   if (mobile) {
  //     let formattedMobile = mobile;
  //     if (formattedMobile.startsWith("0")) {
  //       formattedMobile = formattedMobile.slice(1);
  //     }

  //     try {
  //       const response = await axiosInstance.post(URLS.CREATE_CUSTOMER, {
  //         mobile: formattedMobile,
  //         user_id: user?.user_id,
  //       });
  //       console.log("Create customer response:", response);
  //     } catch (error) {
  //       console.error("Failed to create customer:", error);
  //     }
  //   }
  //   dispatch(setCustomer({ mobile }));

  //   onClose();
  // };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
        <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
        <section
          className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[20px] text-zinc-800 max-md:px-5 z-40"
          style={{ width: "600px" }}
        >
          <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
            <h1 className="flex-auto my-auto">{t("Add Customer")}</h1>
            <button
              type="button"
              onClick={onClose}
              className="text-zinc-800 absolute top-5 right-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </header>
          <main>
            <InputField
              label={t("Enter phone number")}
              placeholder="10XXXXXXXX"
              value={mobile}
              inputType={'number'}
              changed={(e) => {
                setMobile(e);
                console.log("e", e);
              }}
              isRtl={isRtl}
            />
          </main>
          <footer>
            <button
              onClick={handleConfirm}
              className="justify-center items-center px-16 py-3.5 mt-12 text-xl text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
              style={{ width: "500px" }}
            >
              {t("Confirm")}
            </button>
          </footer>
        </section>
      </div>
    </>
  );
}

export default AddCustomer_Modal;
