import React, { useEffect, useState } from "react";
import { substract_icon, add_icon, icon_trash } from "../../../assets";
import { useTranslation } from "react-i18next";

export const ProductCart = ({ product, onDelete, onUpdateQuantity, isReturnProduct, rtl }) => {
  const {t, i18n} = useTranslation();
  const [quantity, setQuantity] = useState(product.quantity);

  useEffect(() => {
    setQuantity(product.quantity);
  }, [product.quantity]);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onUpdateQuantity(product.product_id, newQuantity);
    }
  };

  const increaseQuantity = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    onUpdateQuantity(product.product_id, newQuantity);
  };

  return (
    <div className="flex items-center gap-5 p-3 text-base border border-solid border-slate-200">
      <div className="flex items-center gap-3.5 self-stretch font-medium text-zinc-800 w-8/12">
        <img
          loading="lazy"
          src={product?.image_url || product?.image}
          alt={product?.name}
          className="shrink-0 aspect-square object-contain w-[40px]"
        />
        <div className="flex flex-col">
          <div className="my-auto font-semibold text-neutral-700 text-wrap">
            {product?.product_name}
          </div>
          <div className="my-auto text-zinc-800 text-opacity-70 font-semibold">
            {"EGP "} {product?.price || product?.rate}
            {/* {product?.size || product?.product_uom} at {"EGP "} {product?.price || product?.rate} */}

          </div>
          {product.isReturn && (
            <div className="my-auto text-red-700 text-l font-semibold">
              {t("To Refund")} : -{quantity}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center ml-auto gap-2">
        {!product.isReturn && !rtl && (
          <>
            <button onClick={decreaseQuantity}>
              <img
                loading="lazy"
                src={substract_icon}
                alt="substract_icon"
                className="w-[35px]"
              />
            </button>
            <div className="w-8 text-center">{quantity}</div>
            <button onClick={increaseQuantity}>
              <img
                loading="lazy"
                src={add_icon}
                alt="add_icon"
                className="w-[30px]"
              />
            </button>
          </>
        )}
        {!product.isReturn && rtl && ( 
          <>
            <button onClick={increaseQuantity}>
              <img
                loading="lazy"
                src={add_icon}
                alt="add_icon"
                className="w-[30px]"
              />
            </button>
            <div className="w-8 text-center">{quantity}</div>
            <button onClick={decreaseQuantity}>
              <img
                loading="lazy"
                src={substract_icon}
                alt="substract_icon"
                className="w-[35px]"
              />
            </button>
          </>
        )}
        <div onClick={() => onDelete(product)} className="ms-5 cursor-pointer w-[30px]">
          <img
            loading="lazy"
            src={icon_trash}
            alt="trash_icon"
            className="w-[25px]"
          />
        </div>
      </div>
    </div>
  );
}
