import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import {
  Cashin,
  Cashout,
  // Customers,
  Dashboard,
  Login,
  Navbar,
  OrderCheckout,
  Orders,
  // ProductCo,
  // ReturnOrder,
  Session,
  UserProfile,
} from "./Components/index";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state?.login);
  console.log("auth", user);
  return user ? (
    <>
      <Navbar />
      {children}
    </>
  ) : (
    <Navigate to="/login" />
  );
};
const LoginExist = ({ children }) => {
  const { user } = useSelector((state) => state?.login);
  console.log("auth", user);
  // return user ? children : <Login />;
  return !user ? children : <Navigate to="/session" />;
};

const ProjectRoutes = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <Router>
      <Routes>
        <Route
          path="/Dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/orders"
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          }
        />
        <Route
          path="/Cashin"
          element={
            <PrivateRoute>
              <Cashin />
            </PrivateRoute>
          }
        />
        <Route
          path="/Cashout"
          element={
            <PrivateRoute>
              <Cashout />
            </PrivateRoute>
          }
        />
        {/* // <Route          path="/Customers"       element={            <PrivateRoute>              <Customers />            </PrivateRoute>          }        /> */}
        {/* // <Route          path="/ReturnOrder"     element={            <PrivateRoute>              <ReturnOrder />            </PrivateRoute>          } />  */}
        <Route
          path="/OrderCheckout"
          element={
            <PrivateRoute>
              <OrderCheckout />
            </PrivateRoute>
          }
        />
        {/* <Route          path="/ProductCo"          element={            <PrivateRoute>              <ProductCo />            </PrivateRoute>          }        />   */}
        <Route
          path="/session"
          element={
            <PrivateRoute>
              <Session />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <LoginExist>
              <Login />
            </LoginExist>
          }
        />
        {/* <Route          path="/user"          element={            <LoginExist>              <UserProfile />            </LoginExist>          }        />  */}
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
