import React, { useState, useEffect, useMemo, useTransition } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import { cashInOutData } from '../../Redux/Action/cashinoutdataAction.jsx';
import { useTranslation } from 'react-i18next';

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};


function Table({ columns, data }) {
  const {t, i18n} = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );

  if (data.length === 0) {
    return <p className="text-lg font-semibold text-center mt-4">{t("No data found")}</p>;
  }

  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead className="px-10 py-4 w-full font-semibold text-lg tracking-wide text-left capitalize whitespace-nowrap bg-teal-100 text-zinc-800 max-md:px-5 max-md:max-w-full">
          <tr>
            {headerGroups.map(headerGroup => (
              headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  className="px-6 py-2 text-leftr"
                >
                  {column.render("Header")}
                </th>
              ))
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className=" gap-5 self-center mt-6 w-full tracking-wide capitalize max-w-[983px] text-zinc-800 max-md:flex-wrap max-md:max-w-full"
                style={{ borderBottom: "1px solid #ccc" }}
              >
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-left"
                  >
                    <div className="text-lg text-gray-900">
                      {cell.render("Cell")}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <footer className="flex gap-5 justify-between text-lg self-center px-5 mt-4 w-full max-w-[1134px] max-md:flex-wrap max-md:max-w-full">
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          className="px-3 py-1 rounded-lg border border-gray-100 font-semibold"
        >
          {[5, 10, 15].map(pageSize => (
            <option className="font-semibold"
              key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <div className="flex gap-5 text-center text-black font-semibold">
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className="p-2 rounded-full bg-gray-200" style={{ width: "40px", height: "40px" }}>
            &lt;
          </button>
          <div className="flex-auto my-auto">
            {t("Results")}{" "}
            <strong>
              {pageIndex * pageSize + 1}-{Math.min((pageIndex + 1) * pageSize, data.length)} {t("of")} {data.length}
            </strong>
          </div>
          <button onClick={() => nextPage()} disabled={!canNextPage} className="p-2 rounded-full bg-gray-200" style={{ width: "40px", height: "40px" }}>
            &gt;
          </button>
        </div>
        <form
          className="flex gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            const page = e.target.pageInput.value
              ? Number(e.target.pageInput.value) - 1
              : 0;
            gotoPage(page);
          }}
        >
          <label htmlFor="pageInput" className="my-auto text-black font-semibold">
            {t("Go to :")}
          </label>
          <input
            type="text"
            id="pageInput"
            placeholder="Eg: 14"
            aria-label="Go to page"
            className="px-3 pt-2.5 pb-1 font-semibold rounded-lg bg-zinc-100 text-neutral-400 w-[75px]"
          />
        </form>
      </footer>
    </>
  );
}

function CashIn_Table() {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const session_id = useSelector(state => state.login.user.pos_session_id);
  const user_id = useSelector(state => state.login.user.user_id);
  const type = "cash in";

  // useEffect(() => {
  //   if (session_id && user_id) {
  //     dispatch(cashInOutData(session_id, user_id, type));
  //   }
  // }, [dispatch, session_id, user_id, type]);


  useEffect(() => {
    if (session_id && user_id) {
      dispatch(cashInOutData(session_id, user_id, type))
        .then(() => setLoading(false)) 
        .catch(() => setLoading(false));
    }
  }, [dispatch, session_id, user_id, type]);


  const cashInData = useSelector(state => state.cashInOutData);
  
  const columns = useMemo(
    () => [
      {
        Header: t('Date'),
        accessor: 'date',
      },
      {
        Header: t('Amount'),
        accessor: 'amount',
      },
      {
        Header: t('Reason'),
        accessor: 'reason',
      },
    ],
    []
  );

  const formattedData = useMemo(() => {
    if (cashInData && cashInData.data) {
      return cashInData.data.map(item => ({
        ...item,
        date: new Date(item.date).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        amount: `EGP ${item.amount}`,
      }));
    } else {
      return [];
    }
  }, [cashInData]);

  if (loading) {
    return <LoadingSpinner />; // Show loading spinner until data is loaded
  }

  return (
    <div className="flex flex-col justify-center mr-20 ml-20 mt-10 mb-10 pb-4 text-sm bg-white text-left rounded-[15px] overflow-hidden">
      {formattedData.length === 0 ? (
        <p className="text-lg font-semibold text-center text-zinc-800 mt-4">{t("No data found")}</p>
        // <p className="text-center py-4 text-lg font-semibold text-zinc-800">No Draft Orders</p>

      ) : (
        <Table columns={columns} data={formattedData} loading={loading} />
      )}
    </div>
  );
}

export default CashIn_Table;
