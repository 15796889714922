import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../Redux/Action/userActions";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.user);
  const { login } = useSelector((state) => state);

  console.log("state", login);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>{user}</h1>
      <p>{user?.email}</p>

      <div className="login-info bg-white text-black w-1/3 flex flex-col gap-8 justify-center items-start">
        <ul className="login-info-element ">
          EMail:
          {login?.user?.email}
        </ul>
        <ul className="login-info-element">
          first_name:
          {login?.user?.first_name}
        </ul>
        <ul className="login-info-element">
          last_name:
          {login?.user?.last_name}
        </ul>
        <ul className="login-info-element">
          message:
          {login?.user?.message}
        </ul>
        <ul className="login-info-element">
          mobile:
          {login?.user?.mobile}
        </ul>
        <ul className="login-info-element">
          pos_session_id:
          {login?.user?.pos_session_id}
        </ul>
        <ul className="login-info-element">
          status:
          {login?.user?.status}
        </ul>
        <ul className="login-info-element">
          user_id:
          {login?.user?.user_id}
        </ul>
        <ul className="login-info-element">
          store
          {login?.user?.store_id}
        </ul>
      </div>
    </div>
  );
};

export default UserProfile;
