import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchOrderReceipt } from '../../../Redux/Action/orderReceiptAction';
import OrderReceipt from "./OrderReceipt";
import { plus_icon, send_icon } from "../../../assets/";
import { clearProducts } from "../../../Redux/Action/productsAction";
import { clearCustomer } from "../../../Redux/Action/getcustomerAction";
import { clearReturnProducts } from "../../../Redux/Action/returnproductAction";
import { useTranslation } from "react-i18next";
import OrderReceiptPDF from "./OrderReceiptPDF";


const SESSION_STORAGE_KEY = "products";


function ReceiptOption({ option, icon }) {
    const { t, i18n } = useTranslation();
    return (
        <div className="flex gap-5 py-1.5 pr-6 pl-6 mt-8 text-base text-black-400 bg-white rounded-lg max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
            <input type="text" placeholder={option} className="flex-auto my-auto outline-none" />
            <img src={send_icon} alt="" className={`shrink-0 rounded-md aspect-[1.59] w-[93px] cursor-pointer transition-transform transform ${i18n.language == 'ar' ? 'scale-x-[-1]' : ''}`} onClick={() => console.log("Send receipt")} />
            {/* <img src={send_icon} alt="" className="shrink-0 rounded-md aspect-[1.59] w-[93px] cursor-pointer" onClick={() => console.log("Send receipt")} /> */}
        </div>
    );
}

function NewOrderButton() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();



    const handleNewOrderClick = () => {
        localStorage.removeItem(SESSION_STORAGE_KEY);
        dispatch(clearReturnProducts())

        navigate('/session');


    };

    return (
        <div className="flex flex-col cursor-pointer justify-center px-10 py-4 mx-6 mt-24 bg-[#1B4597] rounded-lg border border-solid border-stone-300 max-md:px-5 h-16" onClick={handleNewOrderClick}>
            <div className="flex gap-3">
                <img
                    src={plus_icon}
                    alt="Cash in icon"
                    className="shrink-0 my-auto  aspect-square w-[18px]"
                />
                <div className="text-white font-bold">{t("New Order")}</div>
            </div>
        </div>
        // <button className="flex flex-col justify-start mt-10 pl-24 text-xl font-semibold tracking-wide text-white capitalize" onClick={handleNewOrderClick}>
        //     <div className="flex flex-col justify-center px-10 py-4 w-full bg-[#1B4597] rounded-lg border border-solid border-stone-300 max-md:px-5">
        //         <div className="flex gap-3">
        //             <img
        //                 src={plus_icon}
        //                 alt="Cash in icon"
        //                 className="shrink-0 my-auto aspect-square w-[18px]"
        //             />
        //             <div>New Order</div>
        //         </div>
        //     </div>
        // </button>
    );
}

function OrderCheckout() {
    const { t,i18n } = useTranslation();
    const [receiptData, setReceiptData] = useState({});
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state?.login);
    const store_id = user?.store_id;
    const orderId = new URLSearchParams(window.location.search).get('order_id');
    const { order } = useSelector((state) => state?.orderReceiptReducer || {});



    const getreceiptdata = async () => {
        // console.log("orderIdfsfs>>>>", orderId)
        const data = await dispatch(fetchOrderReceipt(store_id, orderId));
        // console.log("data", data)
    }

    useEffect(() => {
        // const orderId = new URLSearchParams(window.location.search).get('order_id');
        if (store_id && orderId) {
            getreceiptdata();
            dispatch(clearProducts());
            dispatch(clearCustomer())
            dispatch(clearReturnProducts())

            // dispatch(fetchOrderReceipt(store_id, orderId)); 
        }
    }, []
        //  [dispatch, store_id]
    );

    // const { order } = useSelector((state) => state?.orderReceiptReducer || {});


    useEffect(() => {
        if (order && order.user) {
            setReceiptData(order);
        }
    }, []);


    const receiptOptions = [
        { option: t("WhatsApp number"), icon: "" },
    ];

    // const handlePrintReceipt = () => {
    //     window.print();
    // };

    // console.log("UUUUUUU", user);

    // const handlePrintReceipt = () => {
    //     const newWindow = window.open("", "_blank");
    //     console.log("DDDDDDD", order);
    //     newWindow.document.write(OrderReceiptPDF(order,t, user));
    //     newWindow.document.close();
    //     setTimeout(() => {
    //         newWindow.print();
    //     }, 1000);
    // };

    const handlePrintReceipt = () => {
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
   
        document.body.appendChild(iframe);
   
        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(OrderReceiptPDF(order,t, user));
        doc.close();
   
        iframe.contentWindow.focus();
   
        setTimeout(() => {
        iframe.contentWindow.print();
            document.body.removeChild(iframe);
        }, 1000);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                handlePrintReceipt();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [order]);


    return (
        <>
            <div className="">
                <div className="flex justify-center gap-2 max-md:flex-col max-md:gap-0">
                    <div className="mt-28 w-[40%] ">
                        <section className="flex flex-col px-5  text-xl max-md:mt-10 max-md:max-w-full">
                        <h2 className="mt-24 text-xl font-bold tracking-wide leading-none text-zinc-800 max-md:mt-10 max-md:max-w-full">
                                <span className="text-zinc-800">{t("How would you like to receive the receipt?")}</span>
                            </h2>
                            <button className="justify-center items-center px-16 py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-black bg-gray-200 border border-solid border-stone-300  capitalize rounded-lg max-md:px-5 max-md:max-w-full" onClick={handlePrintReceipt}>
                                {t("Print Receipt")}
                            </button>
                            {/* {receiptOptions.map((option, index) => (
                                <ReceiptOption key={index} option={option.option} icon={option.icon} />
                            ))} */}
                        </section>
                    </div>
                    <NewOrderButton />
                    {receiptData &&
                        <OrderReceipt receiptData={receiptData}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default OrderCheckout;
