const initialState = {
    dashboardData: {
      order_count: 0,
      total_bill_amount: 0,
      opening_cash: 0,
      cash_payment: 0,
      cash_in: 0,
      cash_out: 0,
      available_cash: 0,
    },
    loading: false,
    error: null,
  };
  
  const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DASHBOARD_DATA_REQUEST":
        return { ...state, loading: true, error: null };
      case "GET_DASHBOARD_DATA_SUCCESS":
        return { ...state, loading: false, dashboardData: action.payload };
      case "GET_DASHBOARD_DATA_FAILURE":
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default dashboardReducer;