import { combineReducers } from 'redux';
import {userReducer,loginReducer,sessionidentifierReducer,getcategorydataReducer, storeOrdersReducer, productReducer, customerReducer, orderlinesReducer, createorderReducer, orderReceiptReducer, productsReducer, returnProductReducer, dashboardReducer, cashInOutDataReducer} from "./index"





const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  sessionIdentify:sessionidentifierReducer,
  productData:getcategorydataReducer,
  orders: storeOrdersReducer,
  updatefavproduct: productReducer,
  getcustomer: customerReducer,
  orderlinesReducer: orderlinesReducer,
  createorderReducer:createorderReducer,
  orderReceiptReducer:orderReceiptReducer,
  products: productsReducer,
  returnProductReducer:returnProductReducer,
  dashboard:dashboardReducer,
  cashInOutData:cashInOutDataReducer
});

export default rootReducer;
