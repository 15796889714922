import React, { useState } from "react";
import { Badge } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateFavProduct } from "../../../Redux/Action/updateFavAction";
import { useTranslation } from "react-i18next";

const ProductCard = ({ product, onClick }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const storeId = useSelector((state) => state.login.user.store_id);
    const { user } = useSelector((state) => state?.login);

    const toggleFavorite = async (event) => {
        event.stopPropagation();

        const newFavoriteStatus = !product.isfav;

        try {
            await dispatch(updateFavProduct(product.product_id, storeId, newFavoriteStatus));
        } catch (error) {
            console.error("Failed to update favorite status:", error);
        }
    };

    return (
        <div className="flex flex-col mt-2 ms-2 w-[15%] h-38" onClick={onClick}>
            <div className="relative flex flex-col p-1 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-500 ease-in-out cursor-pointer h-full">
                <button className="absolute top-1 right-1 focus:outline-none" onClick={toggleFavorite}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-7 h-7"
                        viewBox="0 0 24 24"
                        fill={product.isfav ? "gold" : "lightgrey"}
                    >
                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                </button>
                <div className="flex justify-center items-center p-3 rounded-md ">
                <Badge
                        className={`absolute top-2 left-2 text-white font-semibold w-fit px-1 rounded ${
                            product.available_qty > 0 ? "bg-green-800" : "bg-red-600"
                        }`}
                    >
                        <span>{product.available_qty}</span>
                    </Badge>
                    <img
                        loading="lazy"
                        src={product.image_url}
                        className="h-24 w-auto object-contain cursor-pointer"
                        alt={product.product_name}
                    />
                </div>
                <div className="text-sm font-semibold text-gray-800 line-clamp-2">
                    {product.product_name}
                </div>
                <div className="mt-1 text-sm font-bold text-blue-900">
                    {user?.currency} {product.price}
                </div>
            </div>
        </div>
    );
};



const ProductRow = ({ onSelectProduct ,products}) => {
    // const { Productdata } = useSelector((state) => state.productData)


    return (
        <div className="flex flex-wrap  max-h-[100vh] w-[157vh] custom-scrollbar overflow-y-auto scrollbar-button">
            {products?.map((product, idx) => (
                <ProductCard
                    key={idx}
                    product={product}
                    onClick={() => onSelectProduct(product)}
                />
            ))}
        </div>
    );
};

const ProductGrid = ({ onSelectProduct,products }) => {

    return (
        <div className="p-1">
            <ProductRow products={products} onSelectProduct={onSelectProduct} />
        </div>
    );
};
export default ProductGrid;