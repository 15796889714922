import {
    CASH_IN_OUT_DATA_SUCCESS,
    CASH_IN_OUT_DATA_FAILURE,
  } from '../Action/cashinoutdataAction.jsx';
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
  };
  
  const cashInOutDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case CASH_IN_OUT_DATA_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      case CASH_IN_OUT_DATA_FAILURE:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default cashInOutDataReducer;
  