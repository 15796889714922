import { useTranslation } from "react-i18next";

const LanguageChange_Modal = ({ visible, onOk, onCancel, shouldOpenModal}) => {
  const { t } = useTranslation();

  if (!visible || !shouldOpenModal()) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[20px] text-zinc-800 max-md:px-5 z-40"
        style={{ width: "600px" }}
      >
        <header className="flex gap-5 item-center justify-center text-2xl max-md:max-w-full">
          <h1 className="flex-auto my-auto text-center">
            {t("Confirm language change ?")}
          </h1>
        </header>
        <main>
          <p className="text-center text-lg text-zinc-600 my-6">
           {t("By changing the language, your cart and draft orders will be cleared.")}
          </p>
        </main>
        <footer className="flex justify-center gap-4 mt-10">
          <div
            className="px-16 py-3.5 text-xl text-white text-center cursor-pointer rounded-lg max-md:px-5 max-md:max-w-full bg-lime-600 rounded-lg"
            style={{ width: "200px" }}
            onClick={onOk}
          >
            {t("Yes")}
          </div>
          <div
            className="px-16 py-3.5 text-xl text-white text-center cursor-pointer rounded-lg max-md:px-5 max-md:max-w-full bg-red-400 rounded-lg"
            style={{ width: "200px" }}
            onClick={onCancel}
          >
            {t("No")}
          </div>
        </footer>
      </section>
    </div>
  );
};

export default LanguageChange_Modal;
