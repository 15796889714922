import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../Redux/Action/loginAction";
import {
  hide_password_icon,
  my_ginne_logo,
  show_password_icon,
} from "../../assets";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../Modal/language-switcher";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

const LoginPage = ({ onLogin }) => {
  const { t, i18n } = useTranslation();
  const [login, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateEmail(login)) {
      setEmailError("Invalid email address");
      return;
    }

    setLoading(true);

    try {
      const response = await dispatch(loginUser(login, password));
      const message = response.data.message;

      switch (message) {
        case "Session is already open":
          toast.warning(t("Session is already open"));
          break;
        case "Login Failed":
          toast.error(t("Login Failed"));
          break;
        case "Login ID not found":
          toast.error(t("Login ID not found"));
          break;
        case "Incorrect password":
          toast.error(t("Incorrect password"));
          break;
        // default:
        //   toast.success("Login Successful");
        //   break;
      }

      setLoading(false);
    } catch (error) {
      console.error("Error logging in:", error);
      setLoading(false);
    }
  };

  const handleLangChange = async (option) => {
    if (option.value != i18n.language) {
      i18n.changeLanguage(option.value); // Change language using i18next
      localStorage.setItem("selectedLanguage", option.value); // Save selected language to localStorage
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div className="bg-[#EEEEEE] min-h-screen flex items-center justify-center">
      {/* <div className="flex flex-col items-center justify-center px-16 py-16"> */}

      <div className=" absolute end-10 top-5">
        <LanguageSwitcher handleLangChange={handleLangChange} />
      </div>

      {loading && <LoadingSpinner />}
      <div
        className={`flex flex-col items-center justify-center px-16 py-16 ${
          loading ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        <div className="w-auto bg-white items-center justify-center rounded-lg shadow-lg md:mt-10 xl:p-0 bg-gray-50">
          <div className="p-8 space-y-4 md:space-y-6 sm:p-10">
            <div className="flex items-center justify-center mb-10">
              <img className="" src={my_ginne_logo} alt="logo" />
            </div>
            <h1 className="flex flex-col text-3xl font-bold leading-tight tracking-tight text-gray-900 text-black text-center">
              {t("Welcome to POS Web")}
              <span className="text-2xl mb-5 mt-2 font-medium leading-tight tracking-tight text-gray-700 text-center">
                {t("Join us and take your store to the next level!")}
              </span>
            </h1>
            <form className="space-y-4 md:space-y-2" onSubmit={handleLogin}>
              <div>
                <label className="block mb-2 text-l font-medium text-gray-900 dark:text-black">
                  {t("Email Address")}
                </label>
                <input
                  type="login"
                  name="login"
                  id="login"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-l mb-2 rounded-lg focus:outline-none block w-full p-4 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black"
                  placeholder="name@company.com"
                  value={login}
                  style={{ direction: "ltr" }}
                  onChange={handleEmailChange}
                  required
                />
                {emailError && (
                  <p className="text-red-500 mb-5 text-sm">{emailError}</p>
                )}
              </div>
              <div className="relative">
                <label className="block mb-2 text-l font-medium text-gray-900 dark:text-black">
                  {t("Password")}
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  style={{ direction: "ltr" }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 mb-5 sm:text-l rounded-lg focus:outline-none block w-full p-4 pr-12 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black"
                  placeholder="••••••••••••"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <span
                  onClick={toggleShowPassword}
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  style={{ top: "68%", transform: "translateY(-50%)" }}
                >
                  {showPassword ? (
                    <img
                      className="h-6 w-6"
                      src={hide_password_icon}
                      alt="hide password"
                    />
                  ) : (
                    <img
                      className="h-6 w-6"
                      src={show_password_icon}
                      alt="show password"
                    />
                  )}
                </span>
              </div>
              <button
                type="submit"
                className="w-full text-white mt-5 font-bold bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-800 rounded-lg text-xl px-5 py-4 text-center dark:bg-blue-900 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
              >
                {t("Login")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
