import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../Redux/Action/dashboarddataAction";
import { useTranslation } from "react-i18next";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { toast } from "react-toastify";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

const CloseSessionModal = ({ closeModal, handleClose }) => {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state?.login);



  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentDateTime, setCurrentDateTime] = useState({ date: "", time: "" });

  const [diffValue, setDiffValue] = useState();
  const [change, setChange] = useState();
  const [countedCash, setcountedCash] = useState(0);
  const [checked, setChecked] = useState(false);

  const { dashboardData, running, error } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  useEffect(() => {
    setLoading(running);
  }, [running]);

  useEffect(() => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB');
    const formattedTime = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    setCurrentDateTime({ date: formattedDate, time: formattedTime });
  }, []);


  useEffect(() => {
    if (dashboardData?.data) {
      setDiffValue(dashboardData.data.available_cash);
    }
  }, [dashboardData]);


  const handleCloseClick = async () => {

    if (diffValue !== 0 && !checked) {
      toast.error(t("Please accept the payment difference"));
      return;
    }

    setLoading(true);
    logout ();
    // await handleClose();
    setLoading(false);
  };

  const logout = async () => {
    const response = await axiosInstance.post(URLS.LOGOUT_API, {
      session_id: user?.pos_session_id,
      user_id: user?.user_id,
      amount_total: dashboardData?.data?.available_cash,
      counted_amount: countedCash,
      is_accept: checked,
    });
    if (response?.data?.message == "Session closed successfully.") {
      localStorage.clear();
      window.location.reload();
    }

    if (response?.data?.message == "Session is already closed.") {
      localStorage.clear();
      window.location.reload();
    }
    console.log("logout called", response);
  };

  

  const isRTL = i18n.language === 'ar';

  const handleCountedCashChange = (value) => {
    const numericValue = parseFloat(value) || 0;
    setcountedCash(numericValue);
    setDiffValue(dashboardData?.data?.available_cash - numericValue);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };



  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   console.log("msmahhh");
  // };


  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md drop-shadow-sm">
      <div className="relative flex flex-col pb-2 bg-white max-w-[765px] rounded-2xl overflow-hidden">
        <div className="flex gap-5 px-12 pt-4 pb-1 w-full text-3xl font-semibold text-left bg-white text-zinc-800 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex-auto">{t("Closing Summary")}
            <button
              type="button"
              onClick={closeModal}
              className="absolute top-2 right-4 text-gray-600 hover:text-gray-700"
            >
              <svg
                xmlns=""
                className="w-8 h-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-col px-12 mt-4 w-full max-md:px-5 max-md:max-w-full">
          <div className={`flex gap-5 justify-between font-semibold capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full ${isRTL ? 'rtl' : ''}`}>
            <div className="text-lg">{currentDateTime.date}</div>
            <div className="text-base">{currentDateTime.time}</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Total")} {dashboardData?.data?.order_count} {t("Orders")}</div>
            <div className="font-semibold text-blue-900">+ {user?.currency} {dashboardData?.data?.total_bill_amount}</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Cash Payment")}</div>
            <div className="font-semibold">+{user?.currency} {dashboardData?.data?.cash_payment}</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Bank Payment")}</div>
            <div className="font-semibold text-yellow-600">+{user?.currency} 0</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Opening Cash")}</div>
            <div className="font-semibold">+{user?.currency} {dashboardData?.data?.opening_cash}</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Cash In")}</div>
            <div className="font-semibold">+{user?.currency} {dashboardData?.data?.cash_in}</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Cash Out")}</div>
            <div className="font-semibold">-{user?.currency} {dashboardData?.data?.cash_out}</div>
          </div>
          <img
            loading="lazy"
            src=""
            className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
          />
          <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Total Cash Available")}</div>
            <div className="font-semibold">{user?.currency}  {dashboardData?.data?.available_cash}</div>
          </div>
          <div className="flex gap-5 mt-5 max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800 w-5/12 max-md:px-5">
              <div className="text-base">{t("Difference")}</div>
              <div className="text-3xl mt-5 font-semibold" style={{ color: diffValue === 0 ? "green" : "red" }}>
                {diffValue}
              </div>
            </div>
            <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 text-lg bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 w-5/12 max-md:px-5">
              <div className="text-red-600">
                <span className="text-lime-600">{t("Counted Cash")}</span>{" "}
              </div>
              <input
                type="text"
                autoFocus={true}
                className="bg-white rounded-lg border-stone-300 h-[60px] focus:outline-none px-2 font-bold text-zinc-800 w-11/12 text-3xl"
                value={countedCash}
                onChange={(e) => handleCountedCashChange(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-3.5 mt-6 text-base text-zinc-800 max-md:flex-wrap">
            <input
              type="checkbox"
              className="rounded border-gray-300 mt-1 h-5 w-5 "
              onClick={handleCheckboxChange}
            />
            <div className="flex-auto text-lg max-md:max-w-full">
              {" "}
              {t("Accept payments difference.")}{" "}
            </div>
          </div>
          {/* <div className="justify-ce */}
        </div>
        <div className="flex gap-5 self-center mb-2 mt-6 w-full text-xl font-semibold text-center capitalize max-w-[665px] max-md:flex-wrap max-md:max-w-full">
          <button
            className="grow justify-center items-center ms-5 px-10 py-3.5 text-[#7EC242] bg-white rounded-lg border-2 border-[#7EC242] border-solid w-fit max-md:px-5 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50"
            onClick={handleCloseClick}
            disabled={loading}
          >
            {loading && <LoadingSpinner />}
            {t("Close Session")}
          </button>
          <button
            onClick={closeModal}
            className="grow justify-center items-center me-5 px-10 py-3.5 text-white bg-lime-600 rounded-lg w-fit max-md:px-5 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50">
            {t("Continue Selling")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CloseSessionModal;

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-md drop-shadow-sm">
//       <div className="relative flex flex-col pb-2 bg-white max-w-[765px] rounded-2xl overflow-hidden">
//         <div className="flex gap-5 px-12 pt-4 pb-1 w-full text-3xl font-semibold text-left bg-white text-zinc-800 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
//           <div className="flex-auto">{t("Closing Summary")}
//             <button
//               type="button"
//               onClick={closeModal}
//               className="absolute top-2 right-4 text-gray-600 hover:text-gray-700"
//             >
//               <svg
//                 xmlns=""
//                 className="w-8 h-8"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//         <div className="flex flex-col px-12 mt-4 w-full max-md:px-5 max-md:max-w-full">
//           <div className="flex gap-5 justify-between font-semibold capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
//             <div className="text-lg">{currentDateTime.date}</div>
//             <div className="text-base">{currentDateTime.time}</div>
//           </div>
//           <img
//             loading="lazy"
//             src=""
//             className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
//           />
//           <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
//             <div className="text-zinc-800">{t("Total")} {dashboardData?.data?.order_count} {t("Orders")}</div>
//             <div className="font-semibold text-blue-900">+ {user?.currency} {dashboardData?.data?.total_bill_amount}</div>
//           </div>
//           <img
//             loading="lazy"
//             src=""
//             className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
//           />
//           <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
//             <div>{t("Cash Payment")}</div>
//             <div className="font-semibold">+{user?.currency} {dashboardData?.data?.cash_payment}</div>
//           </div>
//           <img
//             loading="lazy"
//             src=""
//             className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
//           />
//           <div className="flex gap-5 justify-between mt-2.5 text-base capitalize max-md:flex-wrap max-md:max-w-full">
//             <div className="text-zinc-800">{t("Bank Payment")}</div>
//             <div className="font-semibold text-yellow-600">+{user?.currency} 0</div>
//           </div>
//           <img
//             loading="lazy"
//             src=""
//             className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
//           />
//           <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
//             <div>{t("Opening Cash")}</div>
//             <div className="font-semibold">+{user?.currency} {dashboardData?.data?.opening_cash}</div>
//           </div>
//           <img
//             loading="lazy"
//             src=""
//             className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
//           />
//           <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
//             <div>{t("Cash In")}</div>
//             <div className="font-semibold">+{user?.currency} {dashboardData?.data?.cash_in}</div>
//           </div>
//           <img
//             loading="lazy"
//             src=""
//             className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
//           />
//           <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
//             <div>{t("Cash Out")}</div>
//             <div className="font-semibold">-{user?.currency} {dashboardData?.data?.cash_out}</div>
//           </div>
//           <img
//             loading="lazy"
//             src=""
//             className="mt-2 w-full border border-solid border-slate-200 stroke-[1px] stroke-slate-200 max-md:max-w-full"
//           />
//           <div className="flex gap-5 justify-between mt-2.5 text-base capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
//             <div>{t("Total Cash Available")}</div>
//             <div className="font-semibold">-{user?.currency}  {dashboardData?.data?.available_cash}</div>
//           </div>
//           <div className="flex gap-5 mt-5 max-md:flex-wrap max-md:max-w-full">
//             <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800 w-5/12 max-md:px-5">
//               <div className="text-base">{t("Difference")}</div>
//               <div className="text-3xl mt-5 font-semibold" style={{ color: change == 0 ? "green" : "red" }}>{change}</div>
//             </div>
//             <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 text-lg bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 w-5/12 max-md:px-5">
//               <div className="text-red-600">
//                 <span className="text-lime-600">{t("Counted Cash")}</span>{" "}
//                 {/* <span className="text-zinc-800">/</span>{" "}
//                 <span className="text-red-600">Loss</span> */}
//               </div>
//               <input
//                 type="text"
//                 autoFocus={true}
//                 className="bg-white rounded-lg border-stone-300 h-[60px] focus:outline-none px-2 font-bold text-zinc-800 w-11/12 text-3xl"
//                 value={countedCash}
//                 onChange={(e) => {
//                   let newValue = e.target.value;
//                   // if (newValue.length > 10) {
//                   //   newValue = newValue.slice(0, 10);
//                   // }
//                   // newValue = newValue.replace(/[^\d.]/g, "");
//                   handleDiffChange(newValue);
//                 }}
//               // readOnly={isBankPaymentSelected}
//               />
//             </div>
//           </div>

//           <div className="flex gap-5 mt-2 max-md:flex-wrap max-md:max-w-full">
//             {/* <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800 w-fit max-md:px-5">
//               <div className="text-base">{t("Available Cash")}</div>
//               <div className="mt-1.5 text-lg font-semibold">{user?.currency} {dashboardData?.data?.available_cash}</div>
//             </div>
//             <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800 w-fit max-md:px-5">
//               <div className="text-base">{t("Counted Cash")}</div>
//               <div className="mt-1.5 text-lg font-semibold"> </div>
//             </div> */}
//             {/* <div className="flex flex-col flex-1 grow shrink-0 items-start py-2 pr-20 pl-6 text-lg bg-white rounded-2xl border border-solid basis-0 border-neutral-400 border-opacity-50 w-fit max-md:px-5">
//               <div className="text-red-600">
//                 <span className="text-lime-600">Profit</span>{" "}
//                 <span className="text-zinc-800">/</span>{" "}
//                 <span className="text-red-600">Loss</span>
//               </div>
//               <div className="mt-1.5 font-semibold text-lime-600">
//                 {" "}
//                 {/* +EGP 600.00{" "} */}
//             {/* </div> */}
//           </div>
//           {/* <div className="flex gap-3.5 mt-6 text-base text-zinc-800 max-md:flex-wrap">
//             <input
//               type="checkbox"
//               className="rounded border-gray-300 focus:border-lime-500 focus:ring focus:ring-lime-500 focus:ring-opacity-50"
//             />
//             <div className="flex-auto max-md:max-w-full">
//               {" "}
//               {t("Accept payments difference and post a profit/loss journal entry")}{" "}
//             </div>
//           </div> */}
//           {/* <div className="justify-center items-start p-3 mt-1">
//             <input
//               type="text"
//               className="block w-full px-3 py-5 text-base leading-normal bg-transparent border border-solid border-neutral-400 rounded-xl focus:outline-none"
//               placeholder="Remarks"
//             />
//           </div>
//           <div className="self-end text-base font-medium tracking-wide text-neutral-400">
//             0/100
//           </div> */}
//         </div>
//         <div className="flex gap-5 self-center mb-2 mt-6 w-full text-xl font-semibold text-center capitalize max-w-[665px] max-md:flex-wrap max-md:max-w-full">
//           {/* <button
//             className="grow justify-center items-center ml-5 px-10 py-3.5 text-[#7EC242] bg-white rounded-lg border-2 border-[#7EC242] border-solid w-fit max-md:px-5 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50"
//             onClick={() => handleClose()}
//           >
//             Close Session
//           </button> */}
//           <button
//             className="grow justify-center items-center ms-5 px-10 py-3.5 text-[#7EC242] bg-white rounded-lg border-2 border-[#7EC242] border-solid w-fit max-md:px-5 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50"
//             onClick={handleCloseClick}
//             disabled={loading}
//           >
//             {loading && <LoadingSpinner />}
//             {t(" Close Session")}
//           </button>
//           <button
//             onClick={closeModal}
//             className="grow justify-center items-center me-5 px-10 py-3.5 text-white bg-lime-600 rounded-lg w-fit max-md:px-5 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-opacity-50">
//             {t("Continue Selling")}
//           </button>
//         </div>
//       </div>
//     </div >
//   );
// }

// export default CloseSessionModal