import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const openCashRegister = (cashboxValue, userId, sessionId) => {
  return async (dispatch) => {
    console.log("VALSSSSSS", cashboxValue);
    try {
      const response = await axiosInstance.post(URLS.OPENING_CASH_API, {
        session_id: sessionId,
        user_id: userId,
        cashbox_value: cashboxValue
      });

      if (response.status === 200) {
        dispatch(openCashRegisterSuccess(response.data));
      } else {
        dispatch(openCashRegisterFailure('Failed to open cash register'));
      }
    } catch (error) {
      dispatch(openCashRegisterFailure(error.message));
    }
  };
};

export const openCashRegisterSuccess = (data) => ({
  type: 'OPEN_CASH_REGISTER_SUCCESS',
  payload: data
});

export const openCashRegisterFailure = (error) => ({
  type: 'OPEN_CASH_REGISTER_FAILURE',
  payload: error
});
