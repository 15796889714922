

// reducer.js

const initialState = {
  orders: [],
  loading: false,
  error: null,
  searchTerm: '',
};

const storeOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STORE_ORDERS_REQUEST":
      return { ...state, loading: true, error: null };
    case "GET_STORE_ORDERS_SUCCESS":
      return { ...state, loading: false, orders: action.payload };
    case "GET_STORE_ORDERS_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "SET_ORDER_SEARCH_TERM":
      return { ...state, searchTerm: action.payload };
    default:
      return state;
  }
};

export default storeOrdersReducer;


// const initialState = {
//     orders: [],
//     loading: false,
//     error: null,
//   };
  
//   const storeOrdersReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case "GET_STORE_ORDERS_REQUEST":
//         return { ...state, loading: true, error: null };
//       case "GET_STORE_ORDERS_SUCCESS":
//         return { ...state, loading: false, orders: action.payload };
//       case "GET_STORE_ORDERS_FAILURE":
//         return { ...state, loading: false, error: action.payload };
//       default:
//         return state;
//     }
//   };
  
//   export default storeOrdersReducer;
  