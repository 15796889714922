import React, { useEffect, useState } from 'react';
import { shop_logo } from '../../../assets';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const LoadingSpinner = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
            <div className="spinner">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};


function OrderReceipt() {
    const { t,i18n } = useTranslation();
    const [loading, setLoading] = useState(false); 
    const { user } = useSelector((state) => state?.login);
    const { order } = useSelector((state) => state?.orderReceiptReducer || {});
     
    useEffect(() => {
        if (order) {
            setLoading(false);
        }
    }, []);

    const renderProductLines = () => {
        return order?.order_lines.map((item, index) => (
            <tr key={index}>
                <td className="text-center align-top">{index + 1}</td>
                <td className="text-left">{item.product_name}</td>
                <td className="text-center">{item.order_qty}</td>
                <td className="text-center">{item.total_amount/item.order_qty}</td>
                <td className="text-center">{item.total_amount }</td>
            </tr>
        ));
    };

    // const store_logo = user?.store_logo;
//   let store_address = user?.address;
//   let store_mobile = user?.retailermobile;

    return (
        <div id="order-receipt" className="flex flex-col text-center w-[35%] max-md:ml-0 max-md:w-full h-full">
            {loading && <LoadingSpinner />}
            <div className="fixed flex flex-col grow ms-40 px-2 bg-white border border-solid border-stone-300 max-md:px-3 max-md:mt-10 max-md:max-w-full h-full">
                <div className="overflow-y-auto custom-scrollbar mb-20 mt-2 px-8" >
                <div className="flex text-center items-center justify-center">
                    <img src={user?.store_logo} alt="Store Logo" width="100" />
                </div>
                <div className='text-center font-base  text-sm'> <strong>{t("Tel")}:</strong> {user?.retailermobile}</div>
                <div className='text-center font-base  text-sm'> <strong>{t("Email")}:</strong> {user?.emailid}</div>
                <div className='text-center font-base  text-sm'> {user?.address}</div>
                <div className="text-center font-bold py-2 text-lg">
                {t("Receipt")}
                </div>
                <div className="border-y-2 border-dashed border-black py-4">
                    <div className="flex justify-between mb-1">
                    <strong>{t("Order No:")}</strong>
                    <span>{order?.receipt_no}</span>
                    </div>
                    <div className="flex justify-between mb-1">
                    <strong>{t("Order Date:")}</strong>
                    <span>{order?.date}</span>
                    </div>
                    <div className="flex justify-between mb-1">
                    <strong>{t("Cashier Name:")}</strong>
                    <span>{user?.first_name}</span>
                    </div>
                    <div className="flex justify-between mb-1">
                    <strong>{t("Customer Name:")}</strong>
                    <span>{order?.customer_name}</span>
                    </div>
                    <div className="flex justify-between mb-1">
                    <strong>{t("Phone No:")}</strong>
                    <span>{order?.customer_mobile}</span>
                    </div>
                </div>
                <table className="w-full mb-2 mt-4 text-sm">
                    <thead className="mb-2">
                        <tr>
                        <th className="text-center">{t("SN")}</th>
                            <th className="text-left pl-4">{t("Description")}</th>
                            <th className="text-right">{t("Qty")}</th>
                            <th className="text-right px-4">{t("Rate")}</th> 
                            <th className="text-right">{t("Amount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderProductLines()}
                    </tbody>
                </table>
                {/* </div> */}
                <div className="border-t-2 border-dashed border-black flex justify-between py-2 mt-4">
                <strong>{t("Total")}</strong>
                <span>{user?.currency} {order?.total_bill_amount}</span>
                </div>
                <div className="flex justify-between">
                    <strong>{t(order?.payment_mode)}</strong>
                    <span>{user?.currency} {order?.amount_paid}</span>
                </div>
                <div className="flex justify-between">
                <strong>{t("Change")}</strong>
                <span>{user?.currency} {order?.amount_return}</span>
                </div>

                <div className="w-full my-4 items-center justify-center flex">
                    <img
                        src={`https://barcode.tec-it.com/barcode.ashx?data=${order?.receipt_no}&code=Code39&translate-esc=on`}
                        alt="Receipt Barcode"
                        className=' self-center h-20 w-72 object-fit'
                        // width={"60%"}
                        // height={'10%'}
                    />
                </div>

                <div className="text-center border-t-2 border-dashed border-black mt-10 text-lg py-4">
                {t("Your Technology Partner")}
                <br />
                    <img src="https://live-production-assets.s3.me-central-1.amazonaws.com/myginne.webp" alt="myGinne Logo" width="140" className="mx-auto mt-1" />
                </div>
            </div>
            </div>
        </div>
    );
}

export default OrderReceipt;






// import React from 'react';
// import { shop_logo } from '../../../assets';

// const staticData = {
//     order_line_details: [
//         { product_name: 'Product 1', delivered_qty: 2, sell_price: 50 },
//         { product_name: 'Product 2', delivered_qty: 1, sell_price: 100 },
//     ],
//     order_number: '123456',
//     order_date: '2023-12-31',
//     customer_details: { name: 'John Doe', mobile1: '1234567890' },
//     order_total_amt: 230,
// };

// function OrderReceipt() {
//     const renderProductLines = () => {
//         return staticData.order_line_details.map((item, index) => (
//             <tr key={index}>
//                 <td className="text-center align-top">{index + 1}</td>
//                 <td className="text-left">{item.product_name}</td>
//                 <td className="text-right">{item.delivered_qty}</td>
//                 <td className="text-right">{item.sell_price}</td>
//                 <td className="text-right">{item.sell_price * item.delivered_qty}</td>
//             </tr>
//         ));
//     };

//     return (
//         <div className="flex flex-col text-center ml-auto w-[35%] max-md:ml-0 max-md:w-full h-full">
//             <div className="fixed flex flex-col grow px-6 w-[30%] bg-white border border-solid border-stone-300 max-md:px-3 max-md:mt-10 max-md:max-w-full h-full">
//                 <div className="text-center pl-48">
//                     <img src={shop_logo} alt="Store Logo" width="100" />
//                 </div>
//                 <div className="text-center font-bold py-2 text-lg">
//                     Order Receipt
//                 </div>
//                 <div className="border-y-2 border-dashed border-black py-4">
//                     <div className="flex justify-between mb-1">
//                         <strong>Order No:</strong>
//                         <span>{staticData.order_number}</span>
//                     </div>
//                     <div className="flex justify-between mb-1">
//                         <strong>Order Date:</strong>
//                         <span>{staticData.order_date}</span>
//                     </div>
//                     <div className="flex justify-between mb-1">
//                         <strong>Customer Name:</strong>
//                         <span>{staticData.customer_details.name}</span>
//                     </div>
//                     <div className="flex justify-between mb-1">
//                         <strong>Phone No:</strong>
//                         <span>{staticData.customer_details.mobile1}</span>
//                     </div>
//                 </div>
//                 <table className="w-full mb-2 mt-10 text-sm">
//                     <thead>
//                         <tr>
//                             <th className="text-center">SN</th>
//                             <th className="text-left">Description</th>
//                             <th className="text-right">Qty</th>
//                             <th className="text-right">Rate</th>
//                             <th className="text-right">Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {renderProductLines()}
                       
//                     </tbody>
//                 </table>
//                 <div className="border-t-2 border-dashed border-black flex justify-between py-4 mt-4">
//                     <strong>Total</strong>
//                     <span>{user?.currency} {staticData.order_total_amt}</span>
//                 </div>
//                 <div className="text-center border-t-2 border-dashed border-black mt-10 text-lg py-4">
//                     Your Technology Partner
//                     <br />
//                     <img src="https://live-production-assets.s3.me-central-1.amazonaws.com/myginne.webp" alt="myGinne Logo" width="150" className="mx-auto mt-4" />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default OrderReceipt;
