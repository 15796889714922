import React from 'react';
import { Link } from 'react-router-dom';
import CashInModal from '../../Modal/CashIn_Modal';
import { arrow_left, plus_icon } from '../../../assets';
import { useTable, usePagination } from 'react-table';
import CashIn_Table from '../../Tables/CashIn_Table';
import { useTranslation } from 'react-i18next';



function CashInButton({ openModal }) {
  const { t, i18n } = useTranslation();

  return (

    <button onClick={openModal} className="flex flex-col justify-center text-xl font-semibold tracking-wide text-white capitalize">
      <div className="flex flex-col justify-center px-10 py-4 w-full bg-lime-600 rounded-lg border border-solid border-stone-300 max-md:px-5">
        <div className="flex gap-3">
          <img
            src={plus_icon}
            alt="Cash in icon"
            className="shrink-0 my-auto aspect-square w-[18px]"
          />
          <div>{t("Cash In")}</div>
        </div>
      </div>
    </button>
  );
}
function Cashin() {
  const { t, i18n } = useTranslation();

  const [isCashInModalOpen, setIsCashInModalOpen] = React.useState(false);

  const openCashInModal = () => {
    setIsCashInModalOpen(true);
  };

  const closeCashInModal = () => {
    setIsCashInModalOpen(false);
  };


  return (
    <>
      {isCashInModalOpen && <CashInModal onClose={closeCashInModal} />} {/* Render CashInModal if isCashInModalOpen is true */}

      <div className="flex gap-5 justify-between px-20 py-2.5 mt-5 max-md:flex-wrap max-md:px-5">
        <div className="flex flex-col justify-center text-lg font-bold text-lime-600">
          <Link to="/dashboard">
            <div className="flex flex-col justify-center px-3.5 py-3.5 w-full bg-white rounded-lg border border-lime-600 border-solid max-md:pr-5">
              <div className="flex gap-1">
                <img
                  src={arrow_left}
                  alt="Back to Dashboard icon"
                  className={`shrink-0 w-8 aspect-square ${i18n.language == 'ar' ? 'scale-x-[-1]' : ''}`}
                />
                <div className="my-auto">{t("Back to Dashboard")}</div>
              </div>
            </div>
          </Link>
        </div>
        <div><CashInButton openModal={openCashInModal} /></div>
      </div>
      <CashIn_Table/>
    </>
  );
}

export default Cashin;
